import React from "react";
import { Link } from "react-router-dom";

import { secciones } from "../../datos";
import Copyright from "./CopyRight";

export function Footer() {
  // destructure footer data
  const { logo, address, email, phone } = secciones[0].footer[0];
  // console.log(secciones[0].footer.map((item) => item));

  return (
    <footer className="w-full bg-gray-900 text-white">
      {secciones[0].footer.map((item, index) => (
        <div className="container mx-auto py-8" key={index}>
          <div className="flex flex-col xl:flex-row text-center xl:text-left gap-y-12">
            {/* info */}
            <div className="w-[45%] mx-auto flex flex-col items-center xl:items-start text-white">
              {/* logo */}
              <Link to="#">
                <img className="mb-[20px] text-white" src={logo} alt="" />
              </Link>
              {/* address */}
              <div className="max-w-[260px] mb-3 text-white font-bold">
                {address}
              </div>
              {/* email */}
              <div className="font-light italic text-white">{email}</div>
              {/* phone */}
              <div className="font-light italic text-white">{phone}</div>
            </div>
            {/* lists */}
            <div className="flex flex-1 flex-col gap-y-14 xl:flex-row justify-between">
              {/* list 1 */}
              <div>
                <div className="text-2xl font-extrabold mb-4 text-white">
                  Interes
                </div>
                <ul className="flex flex-col gap-y-4">
                  {item.list1.map((item, index) => (
                    <li key={index}>
                      <Link className="text-white" to={item.href}>
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              {/* list 2 */}
              <div>
                <div className="text-2xl font-extrabold text-white mb-4 ">
                  Ayuda
                </div>
                <ul className="flex flex-col gap-y-4">
                  {item.list2.map((item, index) => (
                    <li key={index}>
                      <Link className="text-white" to={item.href}>
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              {/* social list */}
              <div>
                <div className="text-2xl font-extrabold text-white mb-4">
                  Redes Sociales
                </div>
                <ul className="flex gap-y-4 gap-x-4 justify-center ">
                  {item.socialList.map((item, index) => (
                    <li
                      className="w-12 h-12 bg-blue-800 flex justify-center items-center rounded-full cursor-pointer hover:bg-red-500 transition-all"
                      key={index}
                    >
                      <Link
                        className="text-white text-xl hover:text-white"
                        to={item.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.icon}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="mt-auto">
        <Copyright />
      </div>
    </footer>
  );
}
