import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Hero } from "../../../components/Web";
import { Formulario } from "../../../api";

const formController = new Formulario();

export function Contact() {
  const [value, setValue] = useState({
    nombre: "",
    correo_electronico: "",
    asunto: "",
    mensaje: "",
  });

  const inputChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const sendData = async (e) => {
    e.preventDefault();
    console.log(value);
    const enviado = await formController.enviarCorreoContacto(value);
    if (!enviado.dato) {
      console.log("Enviando....");
    } else {
      setValue({ nombre: "", correo_electronico: "", asunto: "", mensaje: "" });
    }
  };

  return (
    <>
      <Helmet>
        <title>Inversiones Catelli | Contactanos</title>
        <meta
          name="description"
          content="Contactanos | Somos Catelli "
          data-react-helmet="true"
        />
      </Helmet>
      <section className="hero__secciones">
        <Hero seccion={"contact"} />
      </section>
      <section className="container mx-auto mt-4 md:w-1/2">
        <div className="w-full">
          <iframe
            className="mb-8 w-full h-96"
            id="gmap_canvas"
            title="Inversiones Catelli, SAS"
            src="https://maps.google.com/maps?q=Inversiones%20Catelli&t=&z=13&ie=UTF8&iwloc=&output=embed"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          ></iframe>
        </div>
        <div className="mx-auto w-full">
          <h2 className="text-4xl font-semibold text-center">Contactanos</h2>
          <p className="text-lg font-secondary mt-2 text-center">
            ¿Como podemos ayudar?
          </p>
          <form onSubmit={sendData} className="mx-auto">
            <div className="md:flex-row md:justify-center w-full mb-5">
              <label for="nombre">
                <span className="my-4 py-2 font-bold text-sm">Nombre</span>
                <input
                  className={`${!value} block px-0 w-full text-sm py-2.5 border-0 border-b-2 border-gray-500`}
                  type="text"
                  name="nombre"
                  id="nombre"
                  value={value.nombre}
                  placeholder="Nombre"
                  required
                  onChange={(e) => inputChange(e)}
                />
              </label>
              <label for="correo_electronico">
                <span className="my-4 py-2 font-bold text-sm">
                  Correo electrónico
                </span>
                <input
                  className="block px-0 w-full text-sm py-2.5 border-0 border-b-2 border-gray-500"
                  type="email"
                  value={value.correo_electronico}
                  name="correo_electronico"
                  id="correo_electronico"
                  placeholder="Correo electronico"
                  required
                  onChange={(e) => inputChange(e)}
                />
              </label>
              <label for="asunto">
                <span className="my-4 py-2 font-bold text-sm">Asunto</span>
                <input
                  className={` block px-0 w-full text-sm py-2.5 border-0 border-b-2 border-gray-500`}
                  type="text"
                  name="asunto"
                  id="asunto"
                  value={value.asunto}
                  placeholder="Asunto"
                  required
                  onChange={(e) => inputChange(e)}
                />
              </label>
              <label for="mensaje">
                <span className="my-4 py-2 font-bold text-sm">Mensaje</span>
                <textarea
                  className={` block w-full text-sm border border-gray-400 resize-none px-2`}
                  name="mensaje"
                  id="mensaje"
                  cols="30"
                  rows="10"
                  value={value.mensaje}
                  required
                  onChange={(e) => inputChange(e)}
                ></textarea>
              </label>
            </div>
            <button type="submit">Enviar</button>
          </form>
        </div>
      </section>
    </>
  );
}
