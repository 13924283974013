import React from "react";
import { LoginForm } from "../../../components";

export function Auth() {
  return (
    <div className="w-screen h-screen flex justify-center items-center flex-wrap bg-gradient-to-br from-blue-700 to-red-700">
      <LoginForm />
    </div>
  );
}
