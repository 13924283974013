import React from "react";
import { useRecoilState } from "recoil";
import { activeNavItemState } from "../../../utils";

import { navLink } from "../../../datos";
import { Link, useLocation } from "react-router-dom";

// import SidebarLinkGroup from "../SideBarLinkGroup/";

export function Sidebar() {
  const { pathname } = useLocation();
  return (
    <nav>
      <div>
        {navLink.slice(0, 4).map((link) => (
          <NavItem link={link} pathname={pathname} key={link.id} />
        ))}
        {navLink.slice(4, 6).map((link) => (
          <NavItem link={link} pathname={pathname} key={link.id} />
        ))}
      </div>
      {/* <div className="xl:flex flex-col hidden  items-center justify-center space-y-4 px-4 py-4 ">
        <button className="w-full py-2 px-3 bg-gray-300 text-black font-semibold text-[14px]">
          Logout
        </button>
      </div> */}
    </nav>
  );
}

function NavItem({ link, pathname }) {
  const [activeNav, setActiveNav] = useRecoilState(activeNavItemState);

  const isCurrentPath = (path) => {
    if (path === pathname) return true;
    return false;
  };

  return (
    <Link
      onClick={() => setActiveNav(link.id)}
      key={link.id}
      to={link.url}
      className={`flex h-auto cursor-pointer py-[10px] px-[10px]
       hover:border-gray-900 border-l-4 border-transparent ${
         isCurrentPath(link.url) && "border-b-slate-900"
       } `}
    >
      <span
        className={`text-white text-[12px] md:text-[14px] md:py-0  ${
          isCurrentPath(link.url) && "text-black"
        }} `}
      >
        {link.icon}
      </span>
      <h2
        className={`text-gray-600 ml-3 hover:text-red-300 xl:flex hidden md:mx-5 md:py-2 md:text-blue-50 md:text-2x1 ${
          isCurrentPath(link.url) && "text-red-800"
        }} `}
      >
        {link.title}
      </h2>
    </Link>
  );
}
