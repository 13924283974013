import React, { useState, useEffect, createRef } from "react";
import { Tab, Search, Dropdown, Ref } from "semantic-ui-react";
import { ListGestionHumana } from "../../../components/Admin/GestionHumana";
import "./Gestion.scss";

import { Formulario } from "../../../api";

import { useAuth } from "../../../hooks";

import { areaInteres } from "../../../datos";

const formularioController = new Formulario();

export function GestionHumana() {
  const [reload, setReload] = useState(false);
  const [areaI, setAreaI] = useState([]);
  const [formularios, setFormularios] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const onReload = () => setReload((prevState) => !prevState);

  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState();
  const { accessToken } = useAuth();

  let dropdownItem = createRef();
  let onSearchElement = createRef();

  useEffect(() => {
    (async () => {
      try {
        setFormularios(null);
        setFilteredData(null);
        const response = await formularioController.getGestionHumana(
          accessToken,
          activeIndex,
          page
        );

        setFormularios(response.docs);
        setFilteredData(response.docs);
        setPagination({
          limit: response.limit,
          page: response.page,
          pages: response.pages,
          total: response.total,
        });
      } catch (error) {
        console.error(error);
      }
    })();
  }, [page, reload, activeIndex]);

  const currentTap = (e, { activeIndex }) => {
    setActiveIndex(activeIndex);
    let element = dropdownItem.current?.querySelector('[aria-selected="true"]');

    if (element) {
      dropdownItem.current.querySelector(".clear")?.click();
    }
  };

  const changePage = (_, data) => {
    setPage(data.activePage);
  };

  const onAreaInteres = (e, { value }) => {
    setAreaI(value);
    let filtered = formularios;

    if (value.length > 0) {
      filtered = formularios.filter((item) => {
        return value.includes(item.area_interes);
      });
    }

    setFilteredData(filtered);
  };

  const onSearch = (e, { value }) => {
    let filtered = formularios;
    // Convierte el término de búsqueda a minúsculas para una comparación sin distinción entre mayúsculas y minúsculas
    const searchTermLower = value.toLowerCase();
    if (value.length > 0) {
      filtered = formularios.filter(
        (item) =>
          item.nombre.toLowerCase().includes(searchTermLower) ||
          item.apellido.toLowerCase().includes(searchTermLower) ||
          item.documento_identidad.toLowerCase().includes(searchTermLower) ||
          item.correo_electronico.toLowerCase().includes(searchTermLower) ||
          item.telefono1.toLowerCase().includes(searchTermLower) ||
          item.telefono2.toLowerCase().includes(searchTermLower)
      );
    }

    setFilteredData(filtered);
  };

  const panes = [
    {
      menuItem: {
        key: "1",
        icon: "file",
        content: "Nuevos",
      },
      render: () => (
        <Tab.Pane attached={false}>
          <ListGestionHumana
            prospecto={"nuevo"}
            reload={reload}
            pagination={pagination}
            changePage={changePage}
            filteredData={filteredData}
            activeIndex={activeIndex}
            onReload={onReload}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "2",
        icon: "checkmark",
        content: "Perfiles Seleccionado",
      },

      render: () => (
        <Tab.Pane attached={false}>
          <ListGestionHumana
            prospecto={"seleccionado"}
            reload={reload}
            pagination={pagination}
            changePage={changePage}
            filteredData={filteredData}
            activeIndex={activeIndex}
            onReload={onReload}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "3",
        icon: "times",
        content: "Perfiles Descartado",
      },
      render: () => (
        <Tab.Pane attached={false}>
          <ListGestionHumana
            prospecto={"descartado"}
            reload={reload}
            pagination={pagination}
            changePage={changePage}
            filteredData={filteredData}
            activeIndex={activeIndex}
            onReload={onReload}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "4",
        icon: "warehouse",
        content: "Perfiles Contratado",
      },
      render: () => (
        <Tab.Pane attached={false}>
          <ListGestionHumana
            prospecto={"contratado"}
            reload={reload}
            pagination={pagination}
            changePage={changePage}
            filteredData={filteredData}
            activeIndex={activeIndex}
            onReload={onReload}
          />
        </Tab.Pane>
      ),
    },
  ];

  // console.log(
  //   areaI.map((item) => item),
  //   activeIndex
  // );

  return (
    <div className="mt-2">
      <div className="mx-4 mb-4 flex flex-col items-center justify-center flex-wrap md:flex-row md:justify-start md:items-center">
        <div className="w-full mb-2 md:w-auto mr-2 md:items-center md:mb-0">
          <Ref innerRef={dropdownItem}>
            <Dropdown
              text="Area interes"
              selection
              multiple
              className="icon w-full md:w-auto "
              options={areaInteres}
              onChange={onAreaInteres}
              value={areaI}
              clearable
            />
          </Ref>
        </div>
        <Ref innerRef={onSearchElement}>
          <div className="w-full md:w-auto md:items-center">
            <Search className="w-full " onSearchChange={onSearch} />
          </div>
        </Ref>
      </div>

      <div className="mx-4">
        <Tab
          menu={{
            secondary: false,
            className: `flex flex-col w-full bg-grey-200 p-2 mx-0 md:flex-row md:space-x-8 md:mx-4`,
          }}
          panes={panes}
          activeIndex={activeIndex}
          onTabChange={currentTap}
        />
      </div>
    </div>
  );
}
