import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import hero data
import { hero } from "../../../datos";

export function Hero({ seccion }) {
  // const { title, subtitle, btnText, image } = heroData;
  const [data, setData] = useState("home");

  useEffect(() => {
    const dato = hero.filter((item) =>
      seccion ? item.seccion === seccion : true
    );
    setData(dato[0]);
    // console.log(dato);
  }, [seccion]);

  return (
    <section
      className={`flex flex-col ${
        (data.seccion === "home") | (data.seccion === "recursohumano")
          ? "min-h-screen"
          : "h-14 md:h-[150px] md:mt-14 md:text-center"
      }  bg-slate-800 text-white bg-center bg-cover bg-blend-overlay bg-fixed bg-black/5`}
      style={{ backgroundImage: `url(${data.image})` }}
    >
      {/* Hero Section Content */}
      <div className="flex-1 flex items-center">
        {/* Texto */}
        <div className="text-center mx-auto text-wrap ">
          <h1
            className="text-4xl md:text-6xl lg:text-8xl font-semibold text-white "
            data-aos="fade-down"
            data-aos-delay="400"
          >
            {data?.title}
          </h1>
          {data?.subtitle !== "" ? (
            <p
              className="font-light text-2xl mt-5"
              data-aos="fade-down"
              data-aos-delay="500"
            >
              {data?.subtitle}
            </p>
          ) : null}

          {data?.btnText !== "" ? (
            <Link
              to={data?.url}
              className="btn btn-primary px-5 py-2 inline-block bg-cyan-500 text-white hover:bg-cyan-400 transition-colors mt-10 text-2xl rounded-lg"
              data-aos="fade-down"
              data-aos-delay="600"
            >
              {data?.btnText}
            </Link>
          ) : (
            ""
          )}
          {/* <button
            className="px-5 py-2 inline-block bg-cyan-500 text-white hover:bg-cyan-400 transition-colors mt-10 text-2xl rounded-lg"
            data-aos="fade-down"
            data-aos-delay="600"
          >
            {btnText}
          </button> */}
        </div>
      </div>
    </section>
  );
}
