import React from "react";

import { Header, Footer } from "../../components/";

export function ClientLayout(props) {
  const { children } = props;
  return (
    <div className="overflow-hidden">
      <div className="bg-white">
        <Header />
      </div>
      <div>{children}</div>
      <Footer />
    </div>
  );
}
