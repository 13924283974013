import React, { useRef, useState } from "react";
import { FaAngleDoubleUp, FaAngleDoubleDown } from "react-icons/fa";

export const Accordion = ({
  title,
  content,
  textColor,
  textSize,
  bgContentColor,
  bgTitleColor,
  drawDown,
}) => {
  const [isOpened, setOpened] = useState(false);
  const [height, setHeight] = useState("0px");
  const contentElement = useRef(null);

  const HandleOpening = () => {
    setOpened(!isOpened);
    setHeight(!isOpened ? `${contentElement.current.scrollHeight}px` : "0px");
  };

  return (
    <div onClick={HandleOpening} className="border-b-2">
      <div
        className={`${
          bgTitleColor ? bgTitleColor : "bg-white"
        } p-1 flex justify-between ${textColor ? textColor : "text-black"}`}
      >
        <h4 className="text-black font-bold text-lg  lg:text-xl md:text-2xl">
          {title}
        </h4>
        {drawDown ? (
          isOpened ? (
            <FaAngleDoubleUp />
          ) : (
            <FaAngleDoubleDown />
          )
        ) : (
          <></>
        )}
      </div>
      <div
        ref={contentElement}
        style={{ height: height }}
        className={`${
          bgContentColor ? bgContentColor : "bg-neutral-50"
        } overflow-hidden transition-all duration-200`}
      >
        <p className={`p-1 ${textSize ? textSize : "text-[1.5rem]"}`}>
          {content}
        </p>
      </div>
    </div>
  );
};
