import React from "react";

import { Hero, Cobertura, Servicios, Clientes } from "../../../components";

export function Home() {
  return (
    <>
      <Hero seccion={"home"} />
      <Servicios />
      <Cobertura />
      <Clientes />
    </>
  );
}
