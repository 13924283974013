import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Menu } from "../../../../api";
const menuController = new Menu();

export function NavMobile({ navMobile, setNavMobile }) {
  const [menuData, setMenuData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await menuController.getMenu(true);
        setMenuData(response);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);
  return (
    <ul className="flex flex-col px-6 py-8 gap-y-4">
      {menuData.map((item, index) => {
        return (
          <li key={index}>
            <Link
              to={item.url}
              className="text-xl text-white"
              onClick={() => setNavMobile(!navMobile)}
            >
              {item.title}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
