import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Menu } from "../../../../api";

const menuController = new Menu();

export function Nav({ navMobile, setNavMobile }) {
  const [menuData, setMenuData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await menuController.getMenu(true);
        setMenuData(response);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <nav>
      <ul className="flex gap-x-8">
        {menuData.map((item, index) => {
          return (
            <li key={index}>
              <Link className="text-black text-2xl" to={item.url}>
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
