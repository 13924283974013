import React from "react";

import { secciones } from "../../../../datos";
import { Accordion } from "../../../Accordion/Accordion";

// import "./GestionHumanaPages.scss";

export function GestionHumanaPages() {
  return (
    <>
      {secciones?.map((datos) => (
        <div className="container mt-4 m-auto mb-48">
          {/* Seccion Descripcion */}
          <section className="md:flex md:justify-evenly">
            {/* <h2
          className="w-full text-6xl uppercase font-bold m-0 p-0 text-wrap md:text-8xl   md:p-0 md:mx-2"
          data-aos="fade-down"
          data-aos-delay="800"
        >
          Somos Catelli
        </h2> */}
            <p
              className="text-3xl text-wrap p-0 m-0 text-center md:text-6xl md:m-2 md:text-black"
              data-aos="fade-down"
              data-aos-delay="900"
            >
              {/* Te brindamos seguridad, fomentamos el trabajo en equipo, nos
          preocupamos por desarrollar el talento humano. */}
            </p>
          </section>
          {/* Video */}
          <div className="md:flex md:justify-evenly md:mt-4 md:text-black">
            <section
              className="md:w-[80%] md:m-2 "
              // data-aos="fade-down"
              // data-aos-delay="1000"
            >
              {datos?.recurso_humano[0]?.contenido[0].quebuscamos.map(
                (dato) => (
                  <div className="mt-4">
                    <h2 className="text-lg md:text-[28px] font-extrabold md:text-black">
                      {dato.title}
                    </h2>
                    <p className="align-baseline mt-2 text-justify text-inherit text-[16px] md:text-xl md:text-black">
                      {dato.descripcion}
                    </p>
                  </div>
                )
              )}

              <div
                className="mt-6 m-0 p-0"
                // data-aos="fade-down"
                // data-aos-delay="1500"
              >
                <h2 className="text-lg md:text-[28px] mt-4 mb-4 font-extrabold md:text-black">
                  Valores
                </h2>
                {datos?.recurso_humano[0]?.contenido[0].valores.map((dato) => {
                  const { title } = dato;
                  return (
                    <Accordion
                      title={title}
                      // content={descripcion}
                      textSize={"text-[1rem]"}
                      bgContentColor={"bg-white"}
                      drawDown={false}
                    />
                  );
                })}
              </div>
            </section>
            <section
              className="mt-4 md:w-[100%] md:m-4"
              // data-aos="fade-down"
              // data-aos-delay="1800"
            >
              {/* videos */}
              {/* <Embed id="PDHPxMf7_MY" source="youtube" placeholder={image.logo} /> */}
              <h1 className="text-2x1 font-bold md:text-4xl md:font-bold text-center mb-2 md:text-black">
                {/* Somos una familia */}
              </h1>
              <video
                src={datos.image[0].video_rrhh}
                controls
                preload="auto"
                className="md:w-[90%] md:m-auto"
              ></video>

              <div className="text-center md:m-4">
                <p className="text-4xl md:text-xl font-extralight md:text-black">
                  {datos?.recurso_humano[0]?.contenido[0].texto.map((dato) => (
                    <>{dato.title === "1" ? dato.descripcion : <></>}</>
                  ))}
                </p>
              </div>
            </section>
          </div>
        </div>
      ))}
    </>
  );
}
