import * as Yup from "yup";

export function initialValues(value) {
  const academica = Array.isArray(value)
    ? value.map((item) => ({
        titulo: item.titulo || "",
        centro: item.centro || "",
        anno: item.anno || "",
      }))
    : [{}];

  return { academica };
}

export function validationSchema() {
  return Yup.object({
    academica: Yup.array().of(
      Yup.object({
        titulo: Yup.string().required("Titulo, es requerido."),
        centro: Yup.string().required("Centro, Es requerido."),
        anno: Yup.string().required("Año, es requerido."),
      })
    ),
  });
}
