import * as Yup from "yup";

export function initialValues(value) {
  return {
    documento_identidad: value.documento_identidad || "",
    correo_electronico: value.correo_electronico || "",
  };
}

export function validationSchema() {
  return Yup.object({
    documento_identidad: Yup.string()
      .min(6, "El campo de identidad debe tener al menos 6 caracteres")
      .max(11, "El campo de identidad debe tener como máximo 11 caracteres")
      .required("El campo de identidad es obligatorio"),
    correo_electronico: Yup.string()
      .email(
        "El campo de correo electrónico debe ser un correo electrónico válido"
      )
      .required("El campo de correo electrónico es obligatorio")
      .required(true),
  });
}
