import React from "react";
import { secciones } from "../../../datos";

export function Servicios() {
  return (
    <section className="my-[70px] xl:my-[150px]">
      {secciones.map((datos, index) => (
        // <>{console.log(datos.servicios[0])}</>
        <div className="container mx-auto" key={index}>
          {/* text title */}
          <div className="text-center">
            <h2
              className="h2 mb-16 xl:mb-0"
              data-aos="fade-down"
              data-aos-delay="100"
            >
              {datos.servicios[0].subtitle}
            </h2>
            <p
              className="lead max-w-[980px] mx-auto mb-16 xl:mb-24 text-2xl font-semibold"
              data-aos="fade-down"
              data-aos-delay="200"
            >
              {/* {title} */}
            </p>
          </div>
        </div>
      ))}
    </section>
  );
}
