import * as Yup from "yup";

export function initialValues(value) {
  const familia = Array.isArray(value)
    ? value.map((item) => ({
        nombre_conyugue: item.nombre_conyugue || "",
        fecha_nacimiento: item.fecha_nacimiento || "",
        ocupacion: item.ocupacion || "",
        telefono: item.telefono || "",
        hijos: item.hijos || 0,
        padre: item.padre || "",
        madre: item.madre || "",
      }))
    : [{}];

  return { familia };
}

export function validationSchema() {
  return Yup.object({
    familia: Yup.array().of(
      Yup.object({
        // nombre_conyugue: Yup.string().required("Nombre, es Requerido."),
        // fecha_nacimiento: Yup.string().required(
        //   "Fecha Nacimiento, Es requerido"
        // ),
        // ocupacion: Yup.string().required("Ocupacion, es requerida"),
        // telefono: Yup.string().required("Telefono, es requerido"),
        // hijos: Yup.number().required(false),
        padre: Yup.string().required("Nombre del padre, es requerido"),
        madre: Yup.string().required("Nombre de madre, es requerido"),
      })
    ),
  });
}
