import React from "react";

import { secciones } from "../../../datos";
import { Tarjeta } from "../../Cards";

export function Clientes() {
  // const { title, subtitle, list } = clientes;
  return (
    <section className="my-[70px] xl:my-[90px]">
      {secciones[0].clientes.map((item, index) => {
        return (
          <div className="container mx-auto m-4" key={index}>
            <div className="text-center mb-5">
              <h2
                className="h2 mb-0 xl:mb-0"
                data-aos="fade-down"
                data-aos-delay="100"
              >
                {item.subtitle}
              </h2>
              <p
                className="lead max-w-[584px] mx-auto  xl:mb-24 text-2xl block"
                // data-aos="fade-down"
                // data-aos-delay="200"
              >
                {/* {title} */}
              </p>
            </div>
            <div className="flex flex-col md:flex md:flex-row md:justify-between justify-between gap-4">
              {/* Grid de 4 columnas */}
              {item.list.map((item, index) => {
                const { title, valor, icono } = item;
                return (
                  <Tarjeta
                    titulo={title}
                    valor={valor}
                    icono={icono}
                    colorFondo={"#253f67"}
                    colorTexto={"#fff"}
                    key={index}
                  />
                );
                // <Accordion title={title} content={descripcion} />;
              })}
            </div>
          </div>
        );
      })}
      {/* <div className="w-full md:flex md:space-x-2 md:justify-around mt-3"> */}

      {/* </div> */}
    </section>
  );
}
