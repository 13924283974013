import React, { useState } from "react";
import { Form, Input, Message } from "semantic-ui-react";

import { useFormik } from "formik";
import { initialValues, validationSchema } from "./RegistroForm.form";
import { Formulario } from "../../../../../api";

import "./RegistroForm.scss";

const formController = new Formulario();

export function RegistroForm(props) {
  const { values, setValues } = props;
  const [msg, setMsg] = useState("");
  const [showError, setShowError] = useState(false);

  const formik = useFormik({
    initialValues: initialValues({ ...values }),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      const validar = await formController.validarDocumentos(formValue);
      // console.log(validar);
      if (validar.msg === false) {
        setValues((prevValues) => ({
          ...prevValues,
          ...formValue,
          step: prevValues.step + 1,
        }));
      } else {
        handleOnError();
        setMsg(validar.msg);
      }
    },
  });

  const handleShowError = () => {
    setShowError(true);
    setTimeout(() => setShowError(false), 5000); // oculta el mensaje después de 5 segundos
  };

  const handleOnError = () => {
    handleShowError(); // muestra el mensaje de error si se ha alcanzado el número máximo de errores
  };

  // const validarCorreo = (valor) => {
  //   const expresion = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return expresion.test(valor);
  // };

  return (
    <Form
      className="registrogh-form"
      data-aos="fade-down"
      data-aos-delay="1200"
      data-aos-duration="1000"
      onSubmit={formik.handleSubmit}
    >
      <div className="registrogh-form__document">
        <h2 className="ui centered text-3xl font-semibold">Informacion</h2>
        <h5 className="ui centered text-2xl mb-2">
          Introducir la Informacion requerida.
        </h5>
        {showError && (
          <Message
            color="red"
            size="large"
            header="Validar Informacion"
            content={msg}
          />
        )}
        <Form.Group widths={2}>
          <Form.Field
            control={Input}
            name="documento_identidad"
            label="Documento de Identidad"
            placeholder="Digita un documento de identidad..."
            value={formik.values.documento_identidad}
            onChange={formik.handleChange}
            error={formik.errors.documento_identidad}
          />
        </Form.Group>

        <Form.Group widths={2}>
          <Form.Field
            control={Input}
            name="correo_electronico"
            // value={correo_electronico}
            label="Correo Electronico"
            placeholder="Digita un correo electronico..."
            value={formik.values.correo_electronico}
            onChange={formik.handleChange}
            error={formik.errors.correo_electronico}
          />
        </Form.Group>
        <Form.Button type="submit" primary loading={formik.isSubmitting}>
          Siguiente
        </Form.Button>
      </div>
    </Form>
  );
}
