import React from "react";
import { Hero } from "../../../components";
import { CardSide } from "../../../components/CardSide";
import { Helmet } from "react-helmet";
import useFetch from "../../../hooks/useFetch";

export const PagesProductos = () => {
  const { data, loading, error } = useFetch("/datos.json");

  // useEffect(() => {
  //   fetch("/images.json")
  //     .then((resp) => resp.json())
  //     .then((data) => setCardsData(data));
  // }, []);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <Helmet>
        <title>Inversiones Catelli | Socios Comerciales</title>
        <meta
          name="description"
          content="Socios Comerciales | Somos Catelli "
          data-react-helmet="true"
        />
      </Helmet>
      <section>
        <Hero seccion={"prodcutos"} />
      </section>
      <section
        className="container overflow-y-auto mx-auto"
        // data-aos="fade-down"
        // data-aos-delay="300"
      >
        {/* <h1 className="text-3xl font-bold text-center my-8 mb-8">
          Socios Comerciales
        </h1> */}
        <div className="grid grid-cols-1  md:grid-cols-1  gap-4 mb-4">
          {data.productos.map((card, index) => {
            return (
              <CardSide
                key={index}
                image={card.image}
                description={card.descripcion}
                url={card.url}
                active={card.activa}
              />
            );
          })}
        </div>
      </section>
    </>
  );
};
