export function CardSide({ image, description, url, active }) {
  if (!active) return null;

  console.log(image);

  const imagePath = `img/${image}`; // Use relative path

  return (
    <div
      className="md:relative flex flex-col md:flex-row items-center mt-6 text-gray-700
     bg-white shadow-md bg-clip-border rounded-xl w-full"
    >
      <div className="w-48 h-48 mx-4 overflow-hidden rounded-lg">
        <img
          src={imagePath}
          alt={description}
          className="md:absolute inset-0 object-contain md:w-[120px] md:h-[120px] m-2"
        />
      </div>
      <div className="flex-grow px-8 w-full">
        <h3 className="text-xl font-bold">{description}</h3>
        <p className="mt-2">{url}</p>
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          className="mt-4 inline-block px-4 py-2 bg-blue-500
           text-white font-medium rounded-md shadow hover:bg-blue-600 mb-4"
        >
          Ver más
        </a>
      </div>
    </div>
  );
}
