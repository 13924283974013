import React from "react";
import { Form } from "semantic-ui-react";
import { useFormik } from "formik";
import { Formulario } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { initialValues, validationSchema } from "./ComentarioForm.form";

const formularioController = new Formulario();

export function ComentarioForm(props) {
  const { close, onReload, formulario } = props;
  const { accessToken } = useAuth();

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: validationSchema(formulario),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        await formularioController.createNotas(accessToken, {
          id: formulario._id,
          asunto: formValue.asunto,
          notas: formValue.notas,
        });
        onReload();
        close();
      } catch (error) {
        console.error(error);
      }
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Form.Input
        name="asunto"
        placeholder="Especificar el asunto de la nota"
        onChange={formik.handleChange}
        value={formik.values.asunto}
        error={formik.errors.asunto}
      />
      <Form.TextArea
        name="notas"
        placeholder="Digitalizar la notas requerida"
        onChange={formik.handleChange}
        value={formik.values.notas}
        error={formik.errors.notas}
      />

      <Form.Group widths={"equal"}>
        <Form.Button onClick={() => close()} type="submit" secondary fluid>
          Cancelar
        </Form.Button>
        <Form.Button type="submit" primary fluid>
          Agregar
        </Form.Button>
      </Form.Group>
    </Form>
  );
}
