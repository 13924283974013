import React from "react";
import { BrowserRouter } from "react-router-dom";
import { WebRouter, AdminRouter } from "./router";
import { AuthProvider } from "./contexts";
import { RecoilRoot } from "recoil";
// import animate on scroll
import Aos from "aos";
import "aos/dist/aos.css";

function App() {
  // animate on scroll initialization
  Aos.init({
    duration: 1800,
    offset: 0,
  });
  return (
    // <div className="overflow-hidden">
    <RecoilRoot>
      <AuthProvider>
        <BrowserRouter>
          <WebRouter />
          <AdminRouter />
        </BrowserRouter>
      </AuthProvider>
    </RecoilRoot>
    // </div>
  );
}

export default App;
