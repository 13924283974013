import React from "react";

import {
  // Banner,
  // HomeCourses,
  // HowMyCoursesWork,
  GestionHumanaPages,
  Hero,
} from "../../../components/Web";
// import { image } from "../../../assets";

import "./GestionPages.scss";

export function GestionPages() {
  return (
    <>
      <Hero seccion={"recursohumano"} />
      <GestionHumanaPages />
    </>
  );
}
