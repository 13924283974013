import React, { useCallback } from "react";
import { Form, Button, Image } from "semantic-ui-react";
import { useFormik } from "formik";
import { image } from "../../../../../datos";
import { useDropzone } from "react-dropzone";
import { ENV } from "../../../../../utils";
import { validationSchema, initialValues } from "./UploadForm.form";

import "./UploadForm.scss";
// import { faImages } from "@fortawesome/free-regular-svg-icons";

export function UploadForm(props) {
  const { values, setValues } = props;
  const formik = useFormik({
    initialValues: initialValues(values),
    // validationSchema: validationSchema(),
    onSubmit: async (formValue) => {
      setValues((prevValues) => ({
        ...prevValues,
        ...formValue,
        step: prevValues.step + 1,
      }));
    },
  });

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    console.log(file);
    formik.setFieldValue("url", URL.createObjectURL(file));
    formik.setFieldValue("fileDocument", file);
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
      "application/docx": [".docx"],
    }, //{ "application/*": [".pdf", ".docx"] },
    maxFiles: 1,
    multiple: false,
    onDrop,
  });

  const getAvatar = () => {
    if (formik.values.fileDocument) {
      if (
        formik.values.fileDocument?.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        return image.doc;
      } else if (formik.values.fileDocument?.type === "application/pdf") {
        return image.pdf;
      }
    } else if (formik.values.fileDocument) {
      return `${ENV.BASE_PATH}/${formik.values.fileDocument}`;
    }
    return image?.fileRojo;
  };

  return (
    <div className="uplaod-form">
      <Form onSubmit={formik.handleSubmit}>
        <div className="uplaod-form__avatar" {...getRootProps()}>
          <input {...getInputProps()} />
          <Image size="small" src={getAvatar()} />
          <p>{formik.values.fileDocument?.name}</p>
          {formik.touched.fileDocument && formik.errors.fileDocument ? (
            <div className="text-red-500 text-2xl">
              {formik.errors.fileDocument}
            </div>
          ) : null}
        </div>
        <p className="text-center text-2xl">
          Cargue su documento de hoja de vida.
          <strong> Opcional </strong>
        </p>
        <Form.Group widths={"equal"}>
          <Form.Field>
            <Button color="red" onClick={props.prev}>
              Regresar
            </Button>
            <Button type="submit" primary loading={formik.isSubmitting}>
              Siguiente
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    </div>
  );
}
