import React, { useState } from "react";
import { Container } from "semantic-ui-react";
import {
  RegistroForm,
  DatosPersonales,
  ExpProfesional,
  FormAcademica,
  DatosFamilia,
  ProfileImage,
  UploadForm,
  FinishForm,
  Hero,
  AreaInteresForm,
} from "../../../components/Web";

export function Formulario() {
  const [values, setValues] = useState({
    step: 1,
    nombre: "",
    apellido: "",
    estado_civil: "",
    documento_identidad: "",
    correo_electronico: "",
    genero: "",
    fecha_nacimiento: "",
    telefono1: "",
    telefono2: "",
    direccion: "",
    sector_barrio: "",
    ciudad: "",
    academica: [{}],
    licencia_tiene: "",
    categoria_licencia: "",
    vehiculo_propio: "",
    fileDocument: "",
    url: "",
    experiencia: [{}],
    familia: [{}],
    avatar: "",
    accion: "nuevo",
    area_interes: "",
    herramienta_office: [{}],
    fileAvatar: null,
  });

  const next = () => {
    // if (values)
    setValues({
      ...values,
      step: values.step + 1,
    });
  };

  const prev = () => {
    setValues({
      ...values,
      step: values.step - 1,
    });
  };

  return (
    <>
      <Hero seccion={"formulario"} />
      <Container className="overflow-y-auto mx-auto mt-4">
        <Step
          step={values.step}
          values={values}
          setValues={setValues}
          next={next}
          prev={prev}
        />
      </Container>
    </>
  );
}

const Step = ({ step, values, next, prev, setValues }) => {
  switch (step) {
    case 1:
      return (
        <RegistroForm
          values={values}
          setValues={setValues}
          next={next}
          prev={prev}
        />
      );
    case 2:
      return (
        <AreaInteresForm
          values={values}
          setValues={setValues}
          next={next}
          prev={prev}
        />
      );
    case 3:
      return (
        <DatosPersonales
          values={values}
          setValues={setValues}
          step={step}
          next={next}
          prev={prev}
        />
      );
    case 4:
      return (
        <ExpProfesional
          values={values}
          setValues={setValues}
          step={step}
          next={next}
          prev={prev}
        />
      );
    case 5:
      return (
        <FormAcademica
          values={values}
          setValues={setValues}
          step={step}
          next={next}
          prev={prev}
        />
      );
    case 6:
      return (
        <DatosFamilia
          values={values}
          step={step}
          setValues={setValues}
          next={next}
          prev={prev}
        />
      );
    case 7:
      return (
        <ProfileImage
          values={values}
          step={step}
          setValues={setValues}
          next={next}
          prev={prev}
        />
      );
    case 8:
      return (
        <UploadForm
          values={values}
          step={step}
          setValues={setValues}
          next={next}
          prev={prev}
        />
      );
    case 9:
      return <FinishForm values={values} setValues={setValues} prev={prev} />;
    default:
      return null;
  }
};
