import { ENV } from "../utils";
function stringifyValue(value) {
  if (Array.isArray(value)) {
    return JSON.stringify(value);
  } else if (typeof value === "object" && value !== null) {
    return JSON.stringify(value);
  } else {
    return value;
  }
}
export class Formulario {
  baseApi = ENV.BASE_API;

  async createFormulario(data) {
    try {
      const formData = new FormData();

      Object.keys(data).forEach((key) => {
        // console.log(key, data[key]);
        formData.append(key, stringifyValue(data[key]));
      });

      if (data.fileDocument) {
        formData.append("fileDocument", data.fileDocument);
      }

      if (data.fileAvatar) {
        formData.append("fileAvatar", data.fileAvatar);
      }
      // formData.append("familia", JSON.stringify(data.familia));
      // formData.append("experiencia", JSON.stringify(data.experiencia));
      // formData.append("academica", JSON.stringify(data.academica));

      const url = `${this.baseApi}/${ENV.API_ROUTES.DOCUMENTO}`;
      const params = {
        method: "POST",
        headers: {},
        body: formData,
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 201) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async updateFormularioAccion(accessToken, idForm, formData) {
    try {
      const data = formData;
      const formDatas = new FormData();
      if (data.accion) {
        formDatas.append("accion", data.accion);
      }
      const url = `${ENV.BASE_API}/${ENV.API_ROUTES.DOCUMENTO}/${idForm}`;

      const params = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;
    } catch (error) {
      throw error;
    }
  }

  async getGestionHumana(
    accessToken,
    prospecto = undefined,
    page = 1,
    limit = 10
  ) {
    try {
      const pageFilter = `page=${page || 1}`;
      const limitFilter = `limit=${limit || 10}`;
      const url = `${this.baseApi}/${ENV.API_ROUTES.DOCUMENTOS}?accion=${prospecto}&${pageFilter}&${limitFilter}`;
      const params = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async validarDocumentos(data) {
    const url = `${this.baseApi}/${ENV.API_ROUTES.DOCUMENTO}/validar`;

    console.log(data);
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: token
      },
      body: JSON.stringify(data),
    };

    return fetch(url, params)
      .then((response) => {
        return response.json();
        // return console.log(response.json);
      })
      .then((result) => {
        return result;
      })
      .catch((err) => {
        return err.message;
      });
  }

  async createNotas(accessToken, data) {
    console.log(JSON.stringify(data));
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.NOTA}`;
      const params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      };
      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 201) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async getNotas(accessToken, id) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.NOTAS}?id=${id}`;
      const params = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async enviarCorreoContacto(data) {
    const url = `${this.baseApi}/${ENV.API_ROUTES.ENVIO_CORREO}/sendmail`;

    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: token
      },
      body: JSON.stringify(data),
    };

    return fetch(url, params)
      .then((response) => {
        return response.json();
        // return console.log(response.json);
      })
      .then((result) => {
        return result;
      })
      .catch((err) => {
        return err.message;
      });
  }
}
