import { useState, useEffect } from "react";

export const useFormatoParrafo = (texto) => {
  const [parrafoFormateado, setParrafoFormateado] = useState("");

  useEffect(() => {
    let text2 = texto.toLowerCase();
    const palabras = text2.split(" ");

    const palabrasConPrimeraLetraMayuscula = palabras.map(
      (palabra) => palabra.charAt(0).toUpperCase() + palabra.slice(1)
    );

    const parrafoConMayusculas = palabrasConPrimeraLetraMayuscula.join(" ");

    setParrafoFormateado(parrafoConMayusculas);
  }, [texto]);

  return parrafoFormateado;
};
