import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "../../../datos";
import { Nav, NavMobile } from "../Menu";

// import icons
import { FaBars } from "react-icons/fa";
// import { BsArrowRight } from "react-icons/bs";

export function Header() {
  const [navMobile, setNavMobile] = useState(false);
  return (
    <header
      className="lg:mb-0 fixed z-10 items-center  px-4 lg:px-0 bg-white w-full md:h-24"
      // data-aos="fade-down"
      // data-aos-delay="1200"
      // data-aos-duration="1000"
    >
      <div className="container mx-auto md:p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-[120px]">
            {/* Logo */}
            <Link to={"/"}>
              {/* <img src={Icon.LogoWhite} alt="" /> */}
              <Icon.LogoWhite />
            </Link>

            {/* nav / initially hidden / show on large screens  */}
            <div className="hidden lg:flex">
              <Nav />
            </div>
          </div>

          {/* mobile nav / initially is showing / hide on large screens */}
          <div
            className={`${
              navMobile ? "min-h-screen" : "max-h-0"
            } lg:hidden absolute top-14 bg-blue-800/90 w-full  left-0 right-0 font-bold rounded transition-all overflow-hidden`}
          >
            <NavMobile navMobile={navMobile} setNavMobile={setNavMobile} />
          </div>

          {/* <button className="btn btn-quaternary flex items-center gap-x-[20px] group">
            Request Demo
            <BsArrowRight className="text-xl text-accent-primary group-hover:text-white transition" />
          </button> */}

          {/* nav trigger btn / only shows on mobile screens */}
          <div
            onClick={() => setNavMobile(!navMobile)}
            className="text-2xl text-primary cursor-pointer lg:hidden"
          >
            <FaBars />
          </div>
        </div>
      </div>
    </header>
  );
}
