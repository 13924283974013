import React from "react";
import { Form, Input, Button } from "semantic-ui-react";
import { useFormik } from "formik";
import { initialValues, validationSchema } from "./DatosFamilia.form";
import "./DatosFamilia.scss";
export function DatosFamilia(props) {
  const { values, setValues } = props;

  const formik = useFormik({
    initialValues: initialValues(values.familia),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      setValues((prevValues) => ({
        ...prevValues,
        ...formValue,
        step: prevValues.step + 1,
      }));
    },
  });

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const newFamilia = [...formik.values.familia];
    newFamilia[index][name] = value;
    formik.setFieldValue("familia", newFamilia);
  };

  return (
    <div className="datos-familia">
      <div className="datos-familia__title">
        <h2 className="text-3xl mb-2">Datos Familiares</h2>
      </div>
      <Form onSubmit={formik.handleSubmit}>
        {formik.values.familia.map((input, index) => (
          <div key={index}>
            <Form.Group widths={"equal"}>
              <Form.Field
                control={Input}
                label="Nombre del Conyugue"
                name={`nombre_conyugue`}
                placeholder="Nombre del conyugue"
                value={input.nombre_conyugue}
                onChange={(e) => handleChange(e, index)}
                error={formik.errors.familia?.[index]?.nombre_conyugue}
              />
              <Form.Field
                control={Input}
                label="Fecha Nacimiento"
                name={`fecha_nacimiento`}
                type="Date"
                value={input.fecha_nacimiento}
                onChange={(e) => handleChange(e, index)}
                error={formik.errors.familia?.[index]?.fecha_nacimiento}
              />
              <Form.Field
                control={Input}
                label="Ocupación"
                name={`ocupacion`}
                placeholder="Ocupación del conyugue"
                value={input.ocupacion}
                onChange={(e) => handleChange(e, index)}
                error={formik.errors.familia?.[index]?.ocupacion}
              />
            </Form.Group>
            <Form.Group widths={"equal"}>
              <Form.Field
                control={Input}
                label="Teléfono"
                name={`telefono`}
                placeholder="Número de teléfono"
                value={input.telefono}
                onChange={(e) => handleChange(e, index)}
                error={formik.errors.familia?.[index]?.telefono}
              />
              <Form.Field
                control={Input}
                label="Número de hijo"
                name={`hijos`}
                placeholder="Número de hijo"
                value={input.hijos}
                onChange={(e) => handleChange(e, index)}
                error={formik.errors.familia?.[index]?.hijos}
              />
            </Form.Group>
            <Form.Group widths={"equal"}>
              <Form.Field
                control={Input}
                label="Nombre de tu Padre"
                name={`padre`}
                placeholder="Nombre de tu Padre"
                value={input.padre}
                onChange={(e) => handleChange(e, index)}
                error={formik.errors.familia?.[index]?.padre}
              />
              <Form.Field
                control={Input}
                label="Nombre de tu Madre"
                name={`madre`}
                placeholder="Nombre de tu Madre"
                value={input.madre}
                onChange={(e) => handleChange(e, index)}
                error={formik.errors.familia?.[index]?.madre}
              />
            </Form.Group>
          </div>
        ))}
        <Form.Group widths={"equal"}>
          <Form.Field>
            <Button color="red" onClick={props.prev}>
              Regresar
            </Button>
            <Button type="submit" primary loading={formik.isSubmitting}>
              Siguiente
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    </div>
  );
}
