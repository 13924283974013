import * as Yup from "yup";

export function initialValues(datos) {
  return {
    url: datos?.url || "",
    fileDocument: datos?.fileDocument || null,
  };
}

export function validationSchema() {
  return Yup.object().shape({
    fileDocument: Yup.mixed()
      .required("Es necesario subir un archivo")
      .test(
        "fileSize",
        "El documento a cargar sobre pasa al limite perdido",
        (value) => value && value.size <= 1024 * 1024 * 8 // 2MB
      )
      .test(
        "fileType",
        "Tipo de archivo no permitido",
        (value) =>
          value &&
          [
            "application/pdf",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ].includes(value.type)
      )
      .test("fileFormat", "Solo se permiten archivos pdf y docx", (value) => {
        if (
          value &&
          (value.type === "application/pdf" ||
            value.type ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
        ) {
          return true;
        }
        return false;
      }),
  });
}

// export function validationSchema() {
//   Yup.object().shape({
//     file: Yup.mixed().test(
//       "fileSize",
//       "El archivo es muy grande",
//       (value) => value && value.size <= 1 // 5 MB
//     ),
//   });
// }
