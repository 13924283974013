import React, { useState } from "react";
import { Form, Input, Button } from "semantic-ui-react";
import { useFormik } from "formik";
import { initialValues, validationSchema } from "./FromAcademica.form";
import "./FormAcademica.scss";

export function FormAcademica(props) {
  const { values, setValues } = props;
  const [agregar, setAgregar] = useState(1);

  const handleRemoveFields = (index) => {
    const newAcademica = [...formik.values.academica];
    newAcademica.splice(index, 1);
    formik.setFieldValue("academica", newAcademica);
  };

  const formik = useFormik({
    initialValues: initialValues(values.academica),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      setValues((prevValues) => ({
        ...prevValues,
        ...formValue,
        step: prevValues.step + 1,
      }));
    },
  });

  const addAcademica = () => {
    formik.setFieldValue("academica", [
      ...formik.values.academica,
      {
        titulo: "",
        centro: "",
        anno: "",
      },
    ]);
    setAgregar(agregar + 1);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const newAcademica = [...formik.values.academica];
    newAcademica[index][name] = value;
    formik.setFieldValue("academica", newAcademica);
  };

  return (
    <div className="formacion-academica">
      <div className="formacion-academica__title">
        <h2 className="text-3xl mb-2">Formación Academica</h2>
      </div>
      <div>
        <Form onSubmit={formik.handleSubmit}>
          {formik.values.academica.map((item, index) => (
            <div key={index}>
              <Form.Group widths={"equal"}>
                <Form.Field
                  control={Input}
                  name="titulo"
                  label="Título"
                  placeholder="Título académico o curso"
                  value={item.titulo}
                  onChange={(e) => handleChange(e, index)}
                  error={formik.errors.academica?.[index]?.titulo}
                />
                <Form.Field
                  control={Input}
                  name="centro"
                  label="Institución o Centro Educativo"
                  placeholder="Universidad, Centro Educativo, Escuela"
                  value={item.centro}
                  onChange={(e) => handleChange(e, index)}
                  error={formik.errors.academica?.[index]?.centro}
                />
              </Form.Group>
              <Form.Group widths={"equal"}>
                <Form.Field
                  control={Input}
                  name="anno"
                  label="Año"
                  placeholder="Año"
                  value={item.anno}
                  onChange={(e) => handleChange(e, index)}
                  error={formik.errors.academica?.[index]?.anno}
                />
                {agregar >= 1 && (
                  <Form.Field
                    control={Button}
                    label="Acción"
                    content={"Eliminar"}
                    onClick={() => handleRemoveFields(index)}
                  />
                )}
              </Form.Group>
            </div>
          ))}
          <Form.Group widths={"equal"}>
            <Form.Field>
              <Button color="red" onClick={props.prev}>
                Regresar
              </Button>
              <Button type="submit" primary loading={formik.isSubmitting}>
                Siguiente
              </Button>
              <Button onClick={() => addAcademica()}>Agregar</Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
}
