import React, { useState } from "react";
import { Form, Input, Button, Select } from "semantic-ui-react";
import { useFormik } from "formik";
import { initialValues, validationSchema } from "./ExProfesional.form";

import "./ExperienciaProfesional.scss";

import { salarios, siNo, personalACargo } from "../../../../../datos";

export function ExpProfesional(props) {
  const { values, setValues } = props;
  const [agregar, setAgregar] = useState(1);

  const handleRemoveFields = (index) => {
    const newExperiencia = [...formik.values.experiencia];
    newExperiencia.splice(index, 1);
    formik.setFieldValue("experiencia", newExperiencia);
  };

  const formik = useFormik({
    initialValues: initialValues(values.experiencia),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      setValues((prevValues) => ({
        ...prevValues,
        ...formValue,
        step: prevValues.step + 1,
      }));
    },
  });

  const addExperiencia = () => {
    formik.setFieldValue("experiencia", [
      ...formik.values.experiencia,
      {
        empresa: "",
        cargo: "",
        anno: "",
        salario: "",
        personal_cargo: "",
        departamento: "",
        cargo_actual: "",
        ciudad: "",
      },
    ]);
    setAgregar(agregar + 1);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const newExperiencia = [...formik.values.experiencia];
    newExperiencia[index][name] = value;
    formik.setFieldValue("experiencia", newExperiencia);
  };

  return (
    <div className="experiencia-laboral">
      <div className="experiencia-laboral__title">
        <h2 className="text-3xl mb-2">Experiencial Laboral</h2>
      </div>
      <div>
        <Form onSubmit={formik.handleSubmit}>
          {formik.values.experiencia?.map((exp, index) => (
            <div key={index}>
              <Form.Group widths={"equal"}>
                <Form.Field
                  control={Input}
                  label="Empresa"
                  name={`empresa`}
                  placeholder="Nombre de empresa"
                  value={exp.empresa}
                  onChange={(e) => handleChange(e, index)}
                  error={formik.errors.experiencia?.[index]?.empresa}
                />
                <Form.Field
                  control={Input}
                  label="Cargo"
                  name={`cargo`}
                  placeholder="Cargo"
                  value={exp.cargo}
                  onChange={(e) => handleChange(e, index)}
                  error={formik.errors.experiencia?.[index]?.cargo}
                />
              </Form.Group>
              <Form.Group widths={"equal"}>
                <Form.Field
                  control={Input}
                  label="Ciudad"
                  name={`ciudad`}
                  placeholder="Ciudad"
                  onChange={(e) => handleChange(e, index)}
                  value={exp.ciudad}
                  error={formik.errors.experiencia?.[index]?.ciudad}
                />
                {/* <Form.Field
                  control={Input}
                  label="Salario"
                  name={`salario`}
                  placeholder="Salario"
                  value={exp.salario}
                  onChange={(e) => handleChange(e, index)}
                  error={formik.errors.experiencia?.[index]?.salario}
                /> */}

                <Form.Field
                  control={Select}
                  name={`salario`}
                  options={salarios}
                  placeholder="Seleccione un rango"
                  label="Salario"
                  onChange={(_, e) =>
                    handleChange(
                      { target: { name: e.name, value: e.value } },
                      index
                    )
                  }
                  value={exp.salario}
                  error={formik.errors.experiencia?.[index]?.salario}
                />

                <Form.Field
                  control={Select}
                  label="Personal a Cargo"
                  options={personalACargo}
                  name={`personal_cargo`}
                  placeholder="Personal a Cargo"
                  value={exp.personal_cargo}
                  onChange={(_, e) =>
                    handleChange(
                      {
                        target: { name: e.name, value: e.value },
                      },
                      index
                    )
                  }
                  error={formik.errors.experiencia?.[index]?.personal_cargo}
                />
              </Form.Group>
              <Form.Group widths={"equal"}>
                <Form.Field
                  control={Input}
                  label="Departamento"
                  name={`departamento`}
                  placeholder="Departamento"
                  value={exp.departamento}
                  onChange={(e) => handleChange(e, index)}
                  error={formik.errors.experiencia?.[index]?.departamento}
                />
                <Form.Field
                  control={Input}
                  label="Año"
                  name={`anno`}
                  placeholder="Año"
                  value={exp.anno}
                  onChange={(e) => handleChange(e, index)}
                  error={formik.errors.experiencia?.[index]?.anno}
                />

                <Form.Field
                  control={Select}
                  name={`cargo_actual`}
                  options={siNo}
                  placeholder="¿Cargo Actual?"
                  label="¿Cargo Actual?"
                  value={exp.cargo_actual}
                  onChange={(_, e) =>
                    handleChange(
                      { target: { name: e.name, value: e.value } },
                      index
                    )
                  }
                  error={formik.errors.experiencia?.[index]?.cargo_actual}
                />

                {agregar >= 1 && (
                  <Form.Field
                    control={Button}
                    label="Accion"
                    content={"Eliminar"}
                    onClick={() => handleRemoveFields(index)}
                  />
                )}
              </Form.Group>
            </div>
          ))}
          <Form.Group widths={"equal"}>
            <Form.Field>
              <Button color="red" onClick={props.prev}>
                Regresar
              </Button>
              <Button type="submit" primary loading={formik.isSubmitting}>
                Siguiente
              </Button>
              <Button onClick={() => addExperiencia()}>Agregar</Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
}
