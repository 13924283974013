// import icons
import {
  FaYoutube,
  FaInstagram,
  FaGithub,
  FaBars,
  FaFacebook,
} from "react-icons/fa";
import { BsChatDotsFill } from "react-icons/bs";
import { MdRecentActors, MdVpnKey } from "react-icons/md";
import { ReactComponent as LogoWhite } from "./assets/svg/logo.svg";

import authBg from "./assets/jpg/auth-bg.jpg";
import homeBanner from "./assets/jpg/home-banner.jpg";
import noAvatar from "./assets/jpg/no-avatar.jpg";
import logo from "./assets/png/LogoCatelli_Sin_Fondo.png";
import avatar01 from "./assets/png/avatar_01.png";
import avatar02 from "./assets/png/avatar_02.png";
import avatar03 from "./assets/png/avatar_03.png";
import avatar04 from "./assets/png/avatar_04.png";
import avatar05 from "./assets/png/avatar_05.png";
import avatar06 from "./assets/png/avatar_06.png";
import pdf from "./assets/png/pdf.png";
import doc from "./assets/png/doc.png";
import file from "./assets/png/file.png";
import fileRinbow from "./assets/png/file_r.png";
import fileRojo from "./assets/png/file_red.png";
import rrhh from "./assets/png/rrhh1.png";
import background_home from "./assets/png/catelli_frontal.png";
import touchpc from "./assets/jpg/touchpc.jpg";
import retropc from "./assets/jpg/retropc.jpg";
import comprobado from "./assets/png/cheque.png";
import video_rrhh from "./assets/video/vintrorrhh.mp4";

import BgPage from "./assets/png/catelli_frontal.png";
import GestionHumana from "./assets/png/rrhh1_sm.png";
import Secciones from "./assets/png/header_seccion.png";

// Imagenes de las Secciones
import SantoDomingoNorte from "./assets/jpg/sdn.jpg";
import SantoDomingoEste from "./assets/jpg/sde1.jpg";
import MontePlata from "./assets/jpg/MONTE-PLATA.jpeg";

//Servicios
import logistica from "./assets/jpg/truck640_427_v1.jpg";
import servicioCliente from "./assets/jpg/servicios_cliente_640_427.jpg";
import ejecucion from "./assets/jpg/mercaderista_m.jpg";
import sventas from "./assets/jpg/team-sales_m.webp";
import sventas1 from "./assets/jpg/team_sales_m1.jpg";
//Fondos Servicios
import imageIGb from "./assets/png/feature2_bg.png";
import image2Gb from "./assets/png/feature4_bg.png";

// Clientes
import colmado from "./assets/png/storefront_illustration_2.png";
import market from "./assets/png/market.png";

const Icon = {
  LogoWhite,
};

const image = {
  authBg,
  homeBanner,
  noAvatar,
  logo,
  avatar01,
  avatar02,
  avatar03,
  avatar04,
  avatar05,
  avatar06,
  rrhh,
  background_home,
  pdf,
  doc,
  file,
  fileRinbow,
  fileRojo,
  comprobado,
  touchpc,
  retropc,
  video_rrhh,
};

const secciones = [
  {
    cobertura: [
      {
        title: "Cobertura",
        subtitle:
          "Distribución en las zonas de Santo Domingo Norte, Santo Domingo Este y Monte Plata",
        list: [
          {
            image: SantoDomingoNorte,
            title: "Santo Domingo Norte",
            delay: "400",
          },
          {
            image: SantoDomingoEste,
            title: "Santo Domingo Este",
            delay: "700",
          },
          { image: MontePlata, title: "Monte Plata", delay: "1000" },
        ],
      },
    ],
    servicios: [
      {
        title: "Nuestros Servicios",
        subtitle: "18 años ofreciendo un servicio excepcional",
        list: [
          {
            image: logistica,
            title: "Logística",
            imageGb: imageIGb,
            descripcion: "Contamos con 29 camiones y un equipo de reparto",
            delay: "400",
          },
          {
            image: servicioCliente,
            title: "Servicios al Cliente",
            imageGb: imageIGb,
            descripcion:
              "Entendemos que nuestros clientes son el motor del negocio, por eso nos esforzamos en asegurar un alto servicio al cliente",
            delay: "700",
          },
          {
            image: ejecucion,
            title: "Ejecución y visibilidad",
            imageGb: imageIGb,
            descripcion:
              "Contamos con un equipo de mercaderistas enfocados en asegurar la visibildad de los procutos que distribuimos",
            delay: "1000",
          },
          {
            image: sventas1,
            title: "Ventas",
            imageGb: imageIGb,
            descripcion:
              "Contamos con un equipo de ventas motivado compuesto por 3 supervisores y 27 vendedores",
            delay: "1300",
          },
        ],
      },
    ],
    clientes: [
      {
        title: "Nuestros clientes",
        subtitle: "Entendemos que nuestros clientes son el motor del negocio.",
        list: [
          {
            image: colmado,
            title: "Colmado",
            imageGb: image2Gb,
            icono: "fas fa-users",
            valor: 8107,
            descripcion:
              "Abarcamos alrededor de 8,000+ colmados en la zona de Santo Domingo Norte, Santo Domingo Este y Monte Plata.",
            delay: "400",
          },
          {
            image: market,
            title: "Supermercado",
            imageGb: image2Gb,
            icono: "fas fa-shopping-cart",
            valor: 47,
            descripcion:
              "Brindamos servicio a 150+ Supermercado en la zona de Santo Domingo Norte, Santo Domingo Este y Monte Plata.",
            delay: "700",
          },
          {
            image: ejecucion,
            title: "Mayorista",
            imageGb: image2Gb,
            icono: "fas fa-box-open",
            valor: 146,
            descripcion:
              "Brindamos servicio a 200+ Mayorista en la zona de Santo Domingo Norte, Santo Domingo Este y Monte Plata.",
            delay: "1000",
          },
          // {
          //   image: sventas,
          //   title: "Otros",
          //   imageGb: image2Gb,
          //   icono: "fas fa-map-marker-alt",
          //   valor: 0,
          //   descripcion:
          //     "Manejamos otros tipos de negocios, como farmacia, cafeteria, ferreteria, etc.",
          //   delay: "1300",
          // },
        ],
      },
    ],
    recurso_humano: [
      {
        contenido: [
          {
            texto: [
              {
                title: "1",
                descripcion:
                  "La excelencia es el resultado gradual de luchar siempre para ser mejor",
              },
              {
                title: "2",
                descripcion:
                  "Te brindamos seguridad, fomentamos el trabajo en equipo, nos preocupamos por desarrollar el talento humano.",
              },
            ],
            quebuscamos: [
              {
                title: "Visión",
                descripcion:
                  "Ser reconocido ante nuestros suplidores como el mejor aliado estratégico por la excelencia en el servicio que brindamos y la ejecución excepcional en la distribución, ventas y visibilidad de los productos comercializados.",
              },
              {
                title: "Misión",
                descripcion:
                  "Mantener una relación comercial fuerte, sostenible y auto gestionable con nuestros suplidores y clientes, asegurando el logro de los objetivos y garantizando un servicio de calidad excepcional.",
              },
            ],
            valores: [
              {
                title: "Excelencia",
                descripcion:
                  "es el resultado gradual de hacer siempre lo mejor",
                delay: "400",
                color: "",
              },
              {
                title: "Pasión",
                descripcion:
                  "es querer lo que hacemos y luchar dia a dia por mejorar lo que ofrecemos",
                delay: "400",
                color: "",
              },
              {
                title: "Cooperación",
                descripcion:
                  "es la capacidad de brindar ayuda a los demas para el logro de los objetivos",
                delay: "400",
                color: "",
              },
              {
                title: "Autogestión",
                descripcion:
                  "es la capacidad para asumir responsabilidades, tomar decisiones y ofrecer soluciones efectivas de manera independientes",
                delay: "400",
                color: "",
              },
              {
                title: "Creatividad",
                descripcion:
                  "es la capacidad de crear generando ideas que transforman",
                delay: "400",
                color: "",
              },
              {
                title: "Lealtad",
                descripcion:
                  "es el compromiso de cumplir lo prometido aun en circunstancias adversas.",
                delay: "400",
                color: "",
              },
              {
                title: "Entrega de resultado",
                descripcion:
                  "es la repetición de pequeños actos positivos y constructivos lo que nos lleva a los resultados que deseamos",
                delay: "400",
                color: "",
              },
              {
                title: "Honestidad",
                descripcion:
                  "es una cualidad humana que consiste en comportarse con coherenica y sinceridad",
                delay: "400",
                color: "",
              },
            ],
          },
        ],
      },
    ],
    footer: [
      {
        logo: logo,
        address:
          "Inversiones Catelli, S.R.L, Av. Hermanas Mirabal #39, Villa Mella, Santo Domingo",
        email: "info@catelli.net",
        phone: "809 332 9003",
        list1: [
          {
            name: "Sobre Nosotros",
            href: "/nosotros",
          },
          {
            name: "Gestión Humana",
            href: "/recursohumano",
          },
          {
            name: "Nuestros Socios",
            href: "/socios-comerciales",
          },
          {
            name: "Productos",
            href: "/productos",
          },
        ],
        list2: [
          {
            name: "Contacto",
            href: "/contacto",
          },
          // {
          //   name: "Sign Up",
          //   href: "#",
          // },
          // {
          //   name: "Guide",
          //   href: "#",
          // },
          // {
          //   name: "Reports",
          //   href: "#",
          // },
          // {
          //   name: "Q & A",
          //   href: "#",
          // },
        ],
        socialList: [
          {
            icon: <FaYoutube />,
            href: "http://www.youtube.com/@InversionesCatelliS.A.S",
          },
          {
            icon: <FaInstagram />,
            href: "https://www.instagram.com/invcatelli/",
          },
          {
            icon: <FaFacebook />,
            href: "https://www.facebook.com/catellird",
          },
        ],
      },
    ],
    copyrightData: [
      {
        text: "© CIGUA DR, 2023. All rights reserved.",
        icon: <BsChatDotsFill />,
      },
    ],
    navLink: [
      {
        id: 0,
        title: "Dashboard",
        icon: <FaBars size={"2em"} />,
        url: "/admin",
      },
      {
        id: 1,
        title: "Gestion Humana",
        icon: <MdRecentActors size={"2em"} />,
        url: "/admin/gestionhumana",
      },
      // {
      //   id: 2,
      //   title: "Contactos",
      //   icon: <MdSupervisedUserCircle size={"2em"} />,
      //   url: "/admin/users",
      // },
      // {
      //   id: 3,
      //   title: "Menu",
      //   icon: <MdSupervisedUserCircle size={"2em"} />,
      //   url: "/admin/users",
      // },
      {
        id: 4,
        title: "Usuarios",
        icon: <MdVpnKey size={"2em"} />,
        url: "/admin/users",
      },
    ],
    navigationData: [
      {
        name: "Deliver",
        href: "#",
      },
      {
        name: "About",
        href: "#",
      },
      {
        name: "Features",
        href: "#",
      },
      {
        name: "Signup",
        href: "#",
      },
    ],
    hero: [
      {
        seccion: "home",
        title: `INVERSIONES CATELLI`,
        subtitle: "¡Lider en Distribución!",
        btnText: "",
        image: BgPage,
        url: "",
      },
      {
        seccion: "recursohumano",
        title: "GESTION HUMANA",
        subtitle:
          "Te brindamos seguridad, fomentamos el trabajo en equipo, nos preocupamos por desarrollar el talento humano.",
        btnText: "¿Quiere ser parte de nuestra familia?",
        image: GestionHumana,
        url: "/recursohumano/formulario",
      },
      {
        seccion: "formulario",
        title: "Se parte de nosotros",
        subtitle: "",
        btnText: "",
        image: Secciones,
        url: "",
      },
      {
        seccion: "contact",
        title: "Contactanos",
        subtitle: "",
        btnText: "",
        image: Secciones,
        url: "",
      },
      {
        seccion: "sobrenosotros",
        title: "Sobre Nosotros",
        subtitle: "",
        btnText: "",
        image: Secciones,
        url: "",
      },
    ],
    image: [
      {
        authBg,
        homeBanner,
        noAvatar,
        logo,
        avatar01,
        avatar02,
        avatar03,
        avatar04,
        avatar05,
        avatar06,
        rrhh,
        background_home,
        pdf,
        doc,
        file,
        fileRinbow,
        fileRojo,
        comprobado,
        touchpc,
        retropc,
        video_rrhh,
      },
    ],
  },
];

const copyrightData = {
  text: "© CIGUA DR, 2023. All rights reserved.",
  icon: <BsChatDotsFill />,
};

const navLink = [
  {
    id: 0,
    title: "Dashboard",
    icon: <FaBars size={"2em"} />,
    url: "/admin",
  },
  {
    id: 1,
    title: "Gestion Humana",
    icon: <MdRecentActors size={"2em"} />,
    url: "/admin/gestionhumana",
  },
  // {
  //   id: 2,
  //   title: "Contactos",
  //   icon: <MdSupervisedUserCircle size={"2em"} />,
  //   url: "/admin/users",
  // },
  // {
  //   id: 3,
  //   title: "Menu",
  //   icon: <MdSupervisedUserCircle size={"2em"} />,
  //   url: "/admin/users",
  // },
  {
    id: 4,
    title: "Usuarios",
    icon: <MdVpnKey size={"2em"} />,
    url: "/admin/users",
  },
];

const hero = [
  {
    seccion: "home",
    title: `INVERSIONES CATELLI`,
    subtitle: "¡Lider en Distribución!",
    btnText: "",
    image: BgPage,
    url: "",
  },
  {
    seccion: "recursohumano",
    title: "GESTION HUMANA",
    subtitle:
      "Te brindamos seguridad, fomentamos el trabajo en equipo, nos preocupamos por desarrollar el talento humano.",
    btnText: "¿Quiere ser parte de nuestra familia?",
    image: GestionHumana,
    url: "/recursohumano/formulario",
  },
  {
    seccion: "formulario",
    title: "Se parte de nosotros",
    subtitle: "",
    btnText: "",
    image: Secciones,
    url: "",
  },
  {
    seccion: "contact",
    title: "Contactanos",
    subtitle: "",
    btnText: "",
    image: Secciones,
    url: "",
  },
  {
    seccion: "sobrenosotros",
    title: "Sobre Nosotros",
    subtitle: "",
    btnText: "",
    image: Secciones,
    url: "",
  },
  {
    seccion: "socioscomerciales",
    title: "Socios Comerciales",
    subtitle: "",
    btnText: "",
    image: Secciones,
    url: "",
  },
  {
    seccion: "prodcutos",
    title: "Productos",
    subtitle: "",
    btnText: "",
    image: Secciones,
    url: "",
  },
];

const areaInteres = [
  { key: "1", value: "finanzas", text: "Finanzas" },
  { key: "2", value: "cuentas-por-cobrar", text: "Cuentas por Cobrar" },
  { key: "3", value: "gestion-humana", text: "Gestion Humana" },
  {
    key: "4",
    value: "ejecucion-mercaderistas",
    text: "Ejecucion - Mercaderistas",
  },
  {
    key: "5",
    value: "ventas-ejecutivo-supervisor",
    text: "Ventas - (Ejecutivo de ventas y/o Supervisor)",
  },
  { key: "6", value: "logistica", text: "Logistica" },
  {
    key: "7",
    value: "tecnologia-informacion",
    text: "Tecnologia de Informacion",
  },
  { key: "8", value: "almacen", text: "Almacen" },
  { key: "9", value: "comercial-gerente", text: "Comercial (Gerente)" },
  { key: "10", value: "mensajerias", text: "Mensajerias" },
  { key: "11", value: "conserge", text: "Conserge" },
  { key: "12", value: "seguridad", text: "Seguridad" },
  { key: "13", value: "mecanico", text: "Mercanico" },
  { key: "14", value: "*", text: "Todos" },
];

const salarios = [
  { key: "1", value: "500-10000", text: "500-10000" },
  { key: "2", value: "500-10000", text: "11000-20000" },
  { key: "3", value: "21000-30000", text: "21000-30000" },
  { key: "4", value: "31000-40000", text: "31000-40000" },
  { key: "5", value: "41000-50000", text: "41000-50000" },
  { key: "6", value: "51000-60000", text: "51000-60000" },
  { key: "7", value: "61000-70000", text: "61000-70000" },
  { key: "8", value: "71000-80000", text: "71000-80000" },
  { key: "9", value: "81000-90000", text: "81000-90000" },
  { key: "10", value: "100000+", text: "100000+" },
];

const personalACargo = [
  { key: "1", value: "1", text: "1" },
  { key: "2", value: "2", text: "2" },
  { key: "3", value: "3", text: "3" },
  { key: "4", value: "4", text: "4" },
  { key: "5", value: "5-10", text: "5-10" },
  { key: "6", value: "11-20+", text: "11-20+" },
  { key: "7", value: "100+", text: "100+" },
];

const herrramientasOptions = [
  { value: "world", label: "World" },
  { value: "excel", label: "Excel" },
  { value: "powerpoint", label: "PowerPoint" },
  { value: "powerbi", label: "Power BI" },
  { value: "project", label: "MS Project" },
  { value: "ninguna", label: "Ninguna" },
];

const estadoCivil = [
  { key: "s", text: "Soltero", value: "soltero" },
  { key: "c", text: "Casado", value: "casado" },
  { key: "u", text: "Union Libre", value: "union_libre" },
  { key: "v", text: "Viudo", value: "viudo" },
];
const genderOptions = [
  { key: "m", text: "Masculino", value: "Masculino" },
  { key: "f", text: "Femenino", value: "Femenino" },
];

const siNo = [
  { key: "s", text: "Si", value: "si" },
  { key: "n", text: "No", value: "no" },
];

const licenciaCategoria = [
  { key: "0", text: "No", value: "no" },
  { key: "2", text: "2", value: "2" },
  { key: "3", text: "3", value: "3" },
  { key: "4", text: "4", value: "4" },
  { key: "5", text: "5", value: "5" },
];

export {
  Icon,
  image,
  copyrightData,
  navLink,
  hero,
  areaInteres,
  herrramientasOptions,
  estadoCivil,
  genderOptions,
  licenciaCategoria,
  siNo,
  salarios,
  personalACargo,
  secciones,
};
