import React from "react";
import { Form, Input, Select, TextArea, Button } from "semantic-ui-react";
//Validar Formularios
import { useFormik } from "formik";
import { initialValues, validationSchema } from "./DatosPersonales.form";
import "./DatosPersonales.scss";
import {
  estadoCivil,
  genderOptions,
  siNo,
  licenciaCategoria,
} from "../../../../../datos";

export function DatosPersonales(props) {
  const { values, setValues } = props;

  const formik = useFormik({
    initialValues: initialValues({ ...values }),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      setValues((prevValues) => ({
        ...prevValues,
        ...formValue,
        step: prevValues.step + 1,
      }));
    },
  });

  return (
    <Form className="datos-personales-form" onSubmit={formik.handleSubmit}>
      <h2 className="text-3xl mb-2">Datos personales</h2>
      <Form.Group widths={"equal"}>
        <Form.Field
          control={Input}
          name="nombre"
          placeholder="Digite el nombre"
          label="Nombre"
          onChange={formik.handleChange}
          value={formik.values.nombre}
          error={formik.errors.nombre}
        />
        <Form.Field
          control={Input}
          name="apellido"
          placeholder="Digite el apellido"
          label="Apellido"
          onChange={formik.handleChange}
          value={formik.values.apellido}
          error={formik.errors.apellido}
        />
        <Form.Field
          control={Input}
          name="documento_identidad"
          placeholder="Documento de identidad"
          label="Documento de Identidad"
          onChange={formik.handleChange}
          value={
            formik.values.documento_identidad || values.documento_identidad
          }
          error={formik.errors.documento_identidad}
        />
      </Form.Group>
      <Form.Group widths={"equal"}>
        <Form.Field
          control={Select}
          name="genero"
          options={genderOptions}
          placeholder="Seleciona un genero"
          label="Genero"
          onChange={(_, data) => formik.setFieldValue("genero", data.value)}
          value={formik.values.genero}
          error={formik.errors.genero}
        />
        <Form.Field
          control={Select}
          name="estado_civil"
          options={estadoCivil}
          placeholder="Selecciona un Estado Civil"
          label="Estado Civil"
          onChange={(_, data) =>
            formik.setFieldValue("estado_civil", data.value)
          }
          value={formik.values.estado_civil}
          error={formik.errors.estado_civil}
        />
        <Form.Field
          control={Input}
          name="fecha_nacimiento"
          type="Date"
          label="Fecha Nacimiento"
          onChange={formik.handleChange}
          value={formik.values.fecha_nacimiento}
          error={formik.errors.fecha_nacimiento}
        />
      </Form.Group>
      <Form.Group widths={"equal"}>
        <Form.Field
          control={Input}
          name="correo_electronico"
          placeholder="Digite el correo electronico"
          label="Correo Electronico"
          onChange={formik.handleChange}
          value={formik.values.correo_electronico || values.correo_electronico}
          error={formik.errors.correo_electronico}
        />
        <Form.Field
          control={Input}
          name="telefono1"
          placeholder="18095558888"
          label="Telefono"
          onChange={formik.handleChange}
          value={formik.values.telefono1}
          error={formik.errors.telefono1}
        />
        <Form.Field
          control={Input}
          name="telefono2"
          placeholder="18095558888"
          label="Telefono Movil"
          onChange={formik.handleChange}
          value={formik.values.telefono2}
          error={formik.errors.telefono2}
        />
      </Form.Group>

      <Form.Group widths={"equal"}>
        <Form.Field
          control={TextArea}
          name="direccion"
          rows={1}
          placeholder="Dirección"
          label="Dirección"
          onChange={formik.handleChange}
          value={formik.values.direccion}
          error={formik.errors.direccion}
        />
        <Form.Field
          control={Input}
          name="sector_barrio"
          placeholder="Sector o barrio"
          label="Sector o Barrio"
          onChange={formik.handleChange}
          value={formik.values.sector_barrio}
          error={formik.errors.sector_barrio}
        />
        <Form.Field
          control={Input}
          name="ciudad"
          placeholder="Ciudad"
          label="Ciudad"
          onChange={formik.handleChange}
          value={formik.values.ciudad}
          error={formik.errors.ciudad}
        />
      </Form.Group>

      <Form.Group widths={"equal"}>
        <Form.Field
          control={Select}
          name="licencia_tiene"
          options={siNo}
          placeholder="Tiene licencia de conducir"
          label="¿Tiene licencia de conducir?"
          onChange={(_, data) =>
            formik.setFieldValue("licencia_tiene", data.value)
          }
          value={formik.values.licencia_tiene}
          error={formik.errors.licencia_tiene}
        />

        <Form.Field
          control={Select}
          name="categoria_licencia"
          options={licenciaCategoria}
          placeholder="Seleciona una categoria"
          label="Categoria"
          onChange={(_, data) =>
            formik.setFieldValue("categoria_licencia", data.value)
          }
          value={formik.values.categoria_licencia}
          error={formik.errors.categoria_licencia}
        />

        <Form.Field
          control={Select}
          name="vehiculo_propio"
          options={siNo}
          placeholder="Tiene vehículo"
          label="¿Tiene vehiculo?"
          onChange={(_, data) =>
            formik.setFieldValue("vehiculo_propio", data.value)
          }
          value={formik.values.vehiculo_propio}
          error={formik.errors.vehiculo_propio}
        />
      </Form.Group>

      <Form.Group widths={"equal"}>
        <Form.Field>
          <Button color="red" onClick={props.prev}>
            Regresar
          </Button>
          <Button
            type="submit"
            primary
            loading={formik.isSubmitting}
            // onClick={() => [next()]}
          >
            Siguiente
          </Button>
        </Form.Field>
      </Form.Group>
    </Form>
  );
}
