import React, { useState, useEffect } from "react";

export function Tarjeta({ titulo, valor, icono, colorFondo, colorTexto }) {
  const [valorActual, setValorActual] = useState(0);

  useEffect(() => {
    const intervalo = setInterval(() => {
      setValorActual((prevValor) =>
        prevValor >= valor ? valor : prevValor + 20
      );
    }, 20);

    return () => clearInterval(intervalo);
  }, [valor]);

  return (
    <div
      className={`p-4 rounded-md shadow-md flex flex-col justify-center items-center flex-grow min-h-[200px] min-w-[250px]`}
      style={{ backgroundColor: colorFondo, color: colorTexto }}
    >
      <h3
        className="text-2xl sm:text-3xl md:text-4xl font-semibold mb-2"
        style={{ color: colorTexto }}
      >
        {titulo}
      </h3>
      <div className="flex items-center">
        <span className="text-xl sm:text-2xl md:text-3xl font-bold mr-2">
          {valorActual}
        </span>
        <i className={`${icono} text-lg sm:text-xl md:text-2xl`}></i>
      </div>
    </div>
  );
}
