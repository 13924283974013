import * as Yup from "yup";

export function initialValues(datos) {
  return {
    avatar: datos?.avatar || "",
    fileAvatar: datos?.fileAvatar,
  };
}

export function validationSchema() {
  return Yup.object().shape({
    fileAvatar: Yup.mixed()
      .required("Es necesario subir una foto para su perfil")
      .test(
        "fileSize",
        "El tamaño de la foto debe ser <= 4 MB",
        (value) => value && value.size <= 1024 * 1024 * 4 // 4MB
      )
      .test(
        "fileType",
        "Tipo de archivo no permitido, solo se permite .PNG o .JPG",
        (value) => value && ["image/jpeg", "image/png"].includes(value.type)
      )
      .test("fileFormat", "Solo se permiten archivos pdf y docx", (value) => {
        if (
          value &&
          (value.type === "image/jpeg" || value.type === "image/png")
        ) {
          return true;
        }
        return false;
      }),
  });
}
