import React, { useState } from "react";
import { useFormik } from "formik";
import { Auth } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { inititalValues, validationSchema } from "./LoginForm.form";

// import { image } from "../../../../datos";

const authController = new Auth();

export function LoginForm() {
  const { login } = useAuth();
  const [valid, setValid] = useState(false);
  const [msg, setMsg] = useState("");
  const [showError, setShowError] = useState(false);

  const formik = useFormik({
    initialValues: inititalValues(),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        const response = await authController.login(formValue);
        authController.setAccessToken(response.access);
        authController.setRefreshToken(response.refresh);
        login(response.access);
        setValid(response.access ? true : false);
      } catch (error) {
        console.error(error);
        handleOnError();
        setMsg(error.msg);
      }
    },
  });

  console.log(valid);

  const handleShowError = () => {
    // const MAX_ERRORS = 5;
    // const errorCount = getErrorCount(); // función que devuelve el número actual de errores
    // if (msg) {
    setShowError(true);
    setTimeout(() => setShowError(false), 5000); // oculta el mensaje después de 5 segundos
    // }
  };

  const handleOnError = () => {
    // logError(); // función que registra el error
    handleShowError(); // muestra el mensaje de error si se ha alcanzado el número máximo de errores
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="p-10 bg-white rounded-xl drop-shadow-lg space-y-5"
    >
      <h1 className="text-center text-3xl">Login</h1>
      {showError && (
        <div className="rounded-sm bg-red-200 p-2">
          <p className="w-96 text-center text-wrap text-xl">{msg}</p>
        </div>
      )}
      <div className="flex flex-col space-y-2">
        <label className="text-sm font-light" for="email">
          <span>Correo Electronico</span>
        </label>
        <input
          className="w-96 px-3 py-2 rounded-md border border-state-400"
          type="text"
          id="email"
          placeholder="Correo Electronico"
          onChange={formik.handleChange}
          value={formik.values.email}
          error={formik.errors.email}
        />
        <label className="text-sm font-light" for="password">
          <span>Contraseña</span>
        </label>
        <input
          className="w-96 px-3 py-2 rounded-md border border-state-400"
          type="password"
          id="password"
          placeholder="Contraseña"
          onChange={formik.handleChange}
          value={formik.values.password}
          error={formik.errors.password}
        />
      </div>
      {/* <div>
          <input type="checkbox" name="remember" id="remember" />
          <label class="text-sm font-light" for="remember">
            Remember me
          </label>
        </div> */}
      <button
        type="submit"
        className="flex items-center text-centers justify-center rounded-lg bg-blue-600 px-10 py-2
         text-white w-full hover:bg-blue-500 hover:drop-shadow-md duration-300 ease-in"
      >
        {valid ? (
          <>
            <svg
              class="mr-3 h-5 w-5 animate-spin text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <span class="font-bold text-lg"> Processing... </span>
          </>
        ) : (
          <span class="font-bold  text-lg"> Login </span>
        )}
      </button>
      <p class="text-right">
        <a class="text-blue-600 text-sm font-light hover:underline" href="/#">
          ¿Has olvidado la contraseña?
        </a>
      </p>
    </form>
  );
}
