import * as Yup from "yup";

export function initialValues(form) {
  return {
    asunto: form?.asunto || "",
    notas: form?.notas || "",
  };
}

export function validationSchema(form) {
  return Yup.object({
    asunto: Yup.string().required(true),
    notas: Yup.string().required(true),
  });
}
