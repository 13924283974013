import React, { useCallback } from "react";
import { Button, Form, Image } from "semantic-ui-react";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import { image } from "../../../../../datos";
import { ENV } from "../../../../../utils";
import { validationSchema, initialValues } from "./ProfileImage.form";

import "./ProfileImage.scss";
export function ProfileImage(props) {
  const { values, setValues } = props;

  const formik = useFormik({
    initialValues: initialValues(values),
    validationSchema: validationSchema(),
    onSubmit: async (formValue) => {
      setValues((prevValues) => ({
        ...prevValues,
        ...formValue,
        step: prevValues.step + 1,
      }));
    },
  });

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    formik.setFieldValue("avatar", URL.createObjectURL(file));
    formik.setFieldValue("fileAvatar", file);
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    }, //{ "application/*": [".pdf", ".docx"] },
    maxFiles: 1,
    multiple: false,
    onDrop,
  });

  const getAvatar = () => {
    if (formik.values.fileAvatar) {
      return formik.values.avatar;
    } else if (formik.values.avatar) {
      return `${ENV.BASE_PATH}/${formik.values.avatar}`;
    }
    return image?.noAvatar;
  };

  return (
    <div className="profile-image">
      <Form onSubmit={formik.handleSubmit}>
        <div className="profile-image__avatar" {...getRootProps()}>
          <input {...getInputProps()} />
          <Image size="medium" src={getAvatar()} />
        </div>
        <h3 className="text-center mb-2 font-semibold  text-red-500 text-lg">
          {formik.errors.fileAvatar && formik.touched.fileAvatar && (
            <div>{formik.errors.fileAvatar}</div>
          )}
        </h3>
        <p className="text-center text-black text-3xl">
          Carga una foto para su perfil.
          <p className="text-[12px]">
            "Click a la imagen superior o arraste la imagen."
          </p>
        </p>

        <Form.Group widths={"equal"}>
          <Form.Field>
            <Button color="red" onClick={props.prev}>
              Regresar
            </Button>
            <Button type="submit" primary loading={formik.isSubmitting}>
              Siguiente
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    </div>
  );
}
