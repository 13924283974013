import React, { useState, useEffect } from "react";
import { Loader, Pagination } from "semantic-ui-react";
import { size, map } from "lodash";

import { GestionHumanaItem } from "../GestionHumanaItem";

import "./ListGestionHumana.scss";

export function ListGestionHumana(props) {
  const { onReload, changePage, filteredData, pagination } = props;

  if (!filteredData) return <Loader active inline="centered" />;
  if (size(filteredData) === 0) return "No hay ningun formulario";

  return (
    <div className="list-formularios">
      {map(filteredData, (formulario) => (
        <GestionHumanaItem
          key={formulario._id}
          formulario={formulario}
          onReload={onReload}
        />
      ))}

      <div className="list-formularios__pagination">
        <Pagination
          totalPages={pagination.pages}
          defaultActivePage={pagination.page}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          onPageChange={changePage}
        />
      </div>
    </div>
  );
}
