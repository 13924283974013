import React, { Fragment, useState } from "react";

import { herrramientasOptions } from "../../../../../datos";

export function AreaInteresForm(props) {
  const { values, setValues } = props;
  const [radioValue, setRadioValue] = useState({});
  const [checkValue, setCheckValue] = useState([{}]);
  const [validRadio, setValidRadio] = useState("");

  // const options = [
  //   { value: "world", label: "World" },
  //   { value: "excel", label: "Excel" },
  //   { value: "powerpoint", label: "PowerPoint" },
  //   { value: "powerbi", label: "Power BI" },
  //   { value: "project", label: "MS Project" },
  //   { value: "ninguna", label: "Ninguna" },
  // ];

  const onChangeRadioValue = (e) => {
    setRadioValue(e.target.value);
  };
  const onChangeCheckValue = (e) => {
    setCheckValue({
      ...checkValue,
      [e.target.value]: e.target.checked,
    });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    if (Object.keys(radioValue).length !== 0) {
      setValues({
        ...values,
        area_interes: radioValue,
        herramienta_office: Object.keys(checkValue).filter(
          (key) => checkValue[key]
        ),
        step: values.step + 1,
      });
    } else {
      setValidRadio("Debe especificar un area de interes");
    }
  };

  console.log(checkValue);
  return (
    <Fragment>
      <form
        className="w-full my-4 flex flex-col items-center"
        onSubmit={formSubmit}
      >
        <h2 className="mb-4 items-center font-semibold text-gray-900 text-3xl">
          Area de interes
        </h2>
        <p className="mb-2 text-2xl text-red-300">{validRadio}</p>
        <ul className="w-full  md:w-[686px] text-sm font-semilbold  text-black bg-white border border-blue-400 rounded-lg">
          <li key="1" className="w-full border-b border-blue-200 rounded-t-lg">
            <div className="flex items-center pl-3">
              <input
                id="finanza"
                type="radio"
                value="finanzas"
                name="area_interes"
                // defaultChecked
                onChange={onChangeRadioValue}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  focus:ring-2"
              ></input>
              <label
                htmlFor="finanza"
                className=" w-full py-3 ml-2 text-sm font-semibold text-black"
              >
                Finanzas
              </label>
            </div>
          </li>
          {radioValue === "finanzas" ? (
            <ul className="flex flex-row items-center justify-around md:w-full ">
              {herrramientasOptions.map((item, index) => (
                <li key={index}>
                  <label className=" md:inline-flex items-center">
                    <input
                      className="items-center mx-2"
                      type="checkbox"
                      name="herrramientas"
                      value={item.value}
                      checked={checkValue[item.value] || false}
                      onChange={onChangeCheckValue}
                    ></input>
                    <span className="items-center md:ml-2">{item.label}</span>
                  </label>
                </li>
              ))}
            </ul>
          ) : null}
          <li key="2" className="w-full border-b border-gray-200 rounded-t-lg ">
            <div className="flex items-center pl-3">
              <input
                id="cuentas-por-cobrar"
                type="radio"
                value="cuentas-por-cobrar"
                name="area_interes"
                onChange={onChangeRadioValue}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 "
              ></input>
              <label
                htmlFor="cuentas-por-cobrar"
                className="w-full py-3 ml-2 text-sm font-semibold text-black "
              >
                Cuentas por Cobrar
              </label>
            </div>
          </li>
          {radioValue === "cuentas-por-cobrar" ? (
            <ul className="flex flex-row items-center justify-around md:w-full ">
              {herrramientasOptions.map((item, index) => (
                <li key={index}>
                  <label className=" md:inline-flex items-center">
                    <input
                      className="items-center mx-2"
                      type="checkbox"
                      name="herrramientas"
                      value={item.value}
                      checked={checkValue[item.value] || false}
                      onChange={onChangeCheckValue}
                    ></input>
                    <span className="items-center md:ml-2">{item.label}</span>
                  </label>
                </li>
              ))}
            </ul>
          ) : null}
          <li key="3" className="w-full border-b border-gray-200 rounded-t-lg ">
            <div className="flex items-center pl-3">
              <input
                id="gestion-humana"
                type="radio"
                value="gestion-humana"
                name="area_interes"
                onChange={onChangeRadioValue}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 "
              ></input>
              <label
                htmlFor="gestion-humana"
                className="w-full py-3 ml-2 text-sm font-semibold text-black "
              >
                Gestión Humana
              </label>
            </div>
          </li>
          {radioValue === "gestion-humana" ? (
            <ul className="flex flex-row items-center justify-around md:w-full ">
              {herrramientasOptions.map((item, index) => (
                <li key={index}>
                  <label className=" md:inline-flex items-center">
                    <input
                      className="items-center mx-2"
                      type="checkbox"
                      name="herrramientas"
                      value={item.value}
                      checked={checkValue[item.value] || false}
                      onChange={onChangeCheckValue}
                    ></input>
                    <span className="items-center md:ml-2">{item.label}</span>
                  </label>
                </li>
              ))}
            </ul>
          ) : null}
          <li key="4" className="w-full border-b border-gray-200 rounded-t-lg ">
            <div className="flex items-center pl-3">
              <input
                id="ejecucion-mercaderistas"
                type="radio"
                value="ejecucion-mercaderistas"
                name="area_interes"
                onChange={onChangeRadioValue}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 "
              ></input>
              <label
                htmlFor="ejecucion-mercaderistas"
                className="w-full py-3 ml-2 text-sm font-semibold text-black "
              >
                Ejecución - Mercaderistas
              </label>
            </div>
          </li>
          {radioValue === "ejecucion-mercaderistas" ? (
            <ul className="flex flex-row items-center justify-around md:w-full ">
              {herrramientasOptions.map((item, index) => (
                <li key={index}>
                  <label className=" md:inline-flex items-center">
                    <input
                      className="items-center mx-2"
                      type="checkbox"
                      name="herrramientas"
                      value={item.value}
                      checked={checkValue[item.value] || false}
                      onChange={onChangeCheckValue}
                    ></input>
                    <span className="items-center md:ml-2">{item.label}</span>
                  </label>
                </li>
              ))}
            </ul>
          ) : null}
          <li key="5" className="w-full border-b border-gray-200 rounded-t-lg ">
            <div className="flex items-center pl-3">
              <input
                id="ventas-ejecutivo-supervisor"
                type="radio"
                value="ventas-ejecutivo-supervisor"
                name="area_interes"
                onChange={onChangeRadioValue}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 "
              ></input>
              <label
                htmlFor="ventas-ejecutivo-supervisor"
                className="w-full py-3 ml-2 text-sm font-semibold text-black "
              >
                Ventas - (Ejecutivo de ventas y/o Supervisor)
              </label>
            </div>
          </li>
          {radioValue === "ventas-ejecutivo-supervisor" ? (
            <ul className="flex flex-row items-center justify-around md:w-full ">
              {herrramientasOptions.map((item, index) => (
                <li key={index}>
                  <label className=" md:inline-flex items-center">
                    <input
                      className="items-center mx-2"
                      type="checkbox"
                      name="herrramientas"
                      value={item.value}
                      checked={checkValue[item.value] || false}
                      onChange={onChangeCheckValue}
                    ></input>
                    <span className="items-center md:ml-2">{item.label}</span>
                  </label>
                </li>
              ))}
            </ul>
          ) : null}
          <li key="6" className="w-full border-b border-gray-200 rounded-t-lg ">
            <div className="flex items-center pl-3">
              <input
                id="logistica"
                type="radio"
                value="logistica"
                name="area_interes"
                onChange={onChangeRadioValue}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 "
              ></input>
              <label
                htmlFor="logistica"
                className="w-full py-3 ml-2 text-sm font-semibold text-black "
              >
                Logistica
              </label>
            </div>
          </li>
          <li key="7" className="w-full border-b border-gray-200 rounded-t-lg ">
            <div className="flex items-center pl-3">
              <input
                id="tecnologia-informacion"
                type="radio"
                value="tecnologia-informacion"
                name="area_interes"
                onChange={onChangeRadioValue}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 "
              ></input>
              <label
                htmlFor="tecnologia-informacion"
                className="w-full py-3 ml-2 text-sm font-semibold text-black "
              >
                Tecnologia de Información
              </label>
            </div>
          </li>

          {radioValue === "tecnologia-informacion" ? (
            <ul className="flex flex-row items-center justify-around md:w-full ">
              {herrramientasOptions.map((item, index) => (
                <li key={index}>
                  <label className=" md:inline-flex items-center">
                    <input
                      className="items-center mx-2"
                      type="checkbox"
                      name="herrramientas"
                      value={item.value}
                      checked={checkValue[item.value] || false}
                      onChange={onChangeCheckValue}
                    ></input>
                    <span className="items-center md:ml-2">{item.label}</span>
                  </label>
                </li>
              ))}
            </ul>
          ) : null}
          <li key="8" className="w-full border-b border-gray-200 rounded-t-lg ">
            <div className="flex items-center pl-3">
              <input
                id="almacen"
                type="radio"
                value="almacen"
                name="area_interes"
                onChange={onChangeRadioValue}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 "
              ></input>
              <label
                htmlFor="almacen"
                className="w-full py-3 ml-2 text-sm font-semibold text-black "
              >
                Almacén
              </label>
            </div>
          </li>
          <li key="9" className="w-full border-b border-gray-200 rounded-t-lg ">
            <div className="flex items-center pl-3">
              <input
                id="comercial-gerente"
                type="radio"
                value="comercial-gerente"
                name="area_interes"
                onChange={onChangeRadioValue}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 "
              ></input>
              <label
                htmlFor="comercial-gerente"
                className="w-full py-3 ml-2 text-sm font-semibold text-black "
              >
                Comercial (Gerente)
              </label>
            </div>
          </li>
          {radioValue === "comercial-gerente" ? (
            <ul className="flex flex-row items-center justify-around md:w-full ">
              {herrramientasOptions.map((item, index) => (
                <li key={index}>
                  <label className=" md:inline-flex items-center">
                    <input
                      className="items-center mx-2"
                      type="checkbox"
                      name="herrramientas"
                      value={item.value}
                      checked={checkValue[item.value] || false}
                      onChange={onChangeCheckValue}
                    ></input>
                    <span className="items-center md:ml-2">{item.label}</span>
                  </label>
                </li>
              ))}
            </ul>
          ) : null}
          <li
            key="10"
            className="w-full border-b border-gray-200 rounded-t-lg "
          >
            <div className="flex items-center pl-3">
              <input
                id="mensajerias"
                type="radio"
                value="mensajerias"
                name="area_interes"
                onChange={onChangeRadioValue}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 "
              ></input>
              <label
                htmlFor="mensajerias"
                className="w-full py-3 ml-2 text-sm font-semibold text-black "
              >
                Mensajerias
              </label>
            </div>
          </li>
          <li
            key="11"
            className="w-full border-b border-gray-200 rounded-t-lg "
          >
            <div className="flex items-center pl-3">
              <input
                id="conserge"
                type="radio"
                value="conserge"
                name="area_interes"
                onChange={onChangeRadioValue}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 "
              ></input>
              <label
                htmlFor="conserge"
                className="w-full py-3 ml-2 text-sm font-semibold text-black "
              >
                Conserge
              </label>
            </div>
          </li>
          <li
            key="12"
            className="w-full border-b border-gray-200 rounded-t-lg "
          >
            <div className="flex items-center pl-3">
              <input
                id="seguridad"
                type="radio"
                value="seguridad"
                name="area_interes"
                onChange={onChangeRadioValue}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 "
              ></input>
              <label
                htmlFor="segurida"
                className="w-full py-3 ml-2 text-sm font-semibold text-black "
              >
                Mecánico
              </label>
            </div>
          </li>
        </ul>
        <div className="w-full md:w-96">
          <button className="bg-blue-400 text-2xl text-white mt-4 p-2 rounded-lg w-auto mx-2">
            Regresar
          </button>
          <button
            className="bg-blue-400 text-2xl text-white mt-4 p-2 rounded-lg w-auto"
            type="submit"
          >
            Siguiente
          </button>
        </div>
      </form>
    </Fragment>
  );
}
