import React from "react";
// import about data

import { secciones } from "../../../datos";

export function Cobertura() {
  // destructure about

  return (
    <section className="my-[70px] xl:my-[90px]">
      {secciones[0].cobertura?.map((item, index) => {
        return (
          <div className="container mx-auto" key={index}>
            {/* Texto */}
            <div className="text-center">
              <h2
                className="h2 mb-6 xl:mb-6"
                data-aos="fade-down"
                data-aos-delay="100"
              >
                {item.subtitle}
              </h2>

              <p
                className="lead max-w-[980px] mx-auto mb-16  text-2xl"
                data-aos="fade-down"
                data-aos-delay="200"
              >
                {/* {title} */}
              </p>
            </div>
            {/* Lista de Imagenes */}
            <div className="w-full md:w-full md:flex md:space-x-2 md:justify-around mt-3 rounded-lg">
              {item.list?.map((item, index) => {
                //destructure item
                const { image, title } = item;
                // item
                return (
                  <div
                    key={index}
                    className={`w-full h-72 md:h-96 md:w-full shadow-md rounded-t-lg mt-2`}
                    data-aos="zoom-in"
                    data-aos-offset="100"
                  >
                    <img
                      className="w-full h-full rounded-t-lg"
                      src={image}
                      alt={title}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </section>
  );
}
