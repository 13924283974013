import React from "react";
import { Sidebar } from "../../components";
import { Icon } from "../../datos";
import { useAuth } from "../../hooks";

export function AdminLayout(props) {
  const { children } = props;
  const { user } = useAuth();

  return (
    <div className="flex min-h-screen">
      <div className="xl:w-[200px] bg-blue-600 border border-spacing-10 border-blue-600">
        <Icon.LogoWhite className="md:h-[60px] w-[100%] mx-[0] my-[0px] bg-white mb-2 hidden md:block" />
        <Sidebar />
      </div>
      {/* Right */}
      <div className="sm:w-[calc(100%-200px)] xl:sm:w-[calc(100%-200px)] w-full">
        {/* header */}
        <div className="flex items-center justify-end h-[63px]  bg-blue-500 ">
          <h2 className="mx-2 text-lg text-white font-bold">
            {user.name} {user.lastname}
          </h2>
        </div>
        {/* content */}
        <div className="relative">{children}</div>
      </div>
    </div>
  );
}
