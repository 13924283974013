const SERVER_IP = `catelli.duckdns.org:3978`;
// `${window.location.hostname}:3977`;  `catelli.ddns.net:3977`;

//Todo lo  puedo en cristo

export const ENV = {
  BASE_PATH: `https://${SERVER_IP}`,
  BASE_API: `https://${SERVER_IP}/api/v1`,
  API_ROUTES: {
    REGISTER: "auth/register",
    LOGIN: "auth/login",
    REFRESH_ACCESS_TOKEN: "auth/refresh_access_token",
    USER_ME: "user/me",
    USER: "user",
    USERS: "users",
    MENU: "menu",
    PORTADA: "portada",
    GESTIONHUMANA: "gestion",
    DOCUMENTO_ME: "documento/me",
    DOCUMENTO: "documento",
    DOCUMENTOS: "documentos",
    NOTA: "nota",
    NOTAS: "notas",
    CONTACTANOS: "contactanos",
    NEWSLETTER: "newsletter",
    POST: "post",
    ENVIO_CORREO: "correo",
  },
  JWT: {
    ACCESS_TOKEN: "accessToken",
    REFRESH_TOKEN: "refreshToken",
  },
};
