import React, { useState } from "react";
import { Form, Button, Image, Card } from "semantic-ui-react";
import { useFormik } from "formik";
import { Formulario } from "../../../../../api";
import { image } from "../../../../../datos";
import { ENV } from "../../../../../utils";
import { useNavigate } from "react-router-dom";

import "./FinishForm.scss";

const formController = new Formulario();

export function FinishForm({ values, setValues, prev }) {
  const [estado, setEstado] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      ...values,
    },
    onSubmit: async (formValue) => {
      const finish = await formController.createFormulario(formValue);
      if (finish) setEstado(true);
    },
  });

  const handleFinish = () => {
    navigate("/recursohumano");
  };

  const getAvatar = () => {
    if (values.fileAvatar) {
      return values.avatar;
    } else if (values.avatar) {
      return `${ENV.BASE_PATH}/${values.avatar}`;
    }
  };

  return (
    <div className="completar-form">
      {estado !== true ? (
        <div className="completar-form__content flex justify-center ">
          <Form className="mx-4" onSubmit={formik.handleSubmit}>
            <Form.Group widths={"equal"}>
              <Card.Group>
                <Card>
                  <Card.Content>
                    <Image floated="right" size="medium" src={getAvatar()} />
                    <Card.Header>
                      {values.nombre
                        ? values.nombre + " " + values.apellido
                        : "Heriberto Gonzalez"}
                    </Card.Header>
                    <Card.Meta>
                      <p className="font-bold">
                        {values.documento_identidad
                          ? values.documento_identidad
                          : "03700749892"}
                      </p>
                    </Card.Meta>
                    <Card.Description>
                      <p>
                        {values.correo_electronico
                          ? values.correo_electronico
                          : " heriberto777@gmail.com"}
                      </p>
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <div className="ui two buttons">
                      <Button basic color="red" onClick={prev}>
                        Regresar
                      </Button>
                      <Button basic color="green" loading={formik.isSubmitting}>
                        Terminar proceso
                      </Button>
                    </div>
                  </Card.Content>
                </Card>
              </Card.Group>
            </Form.Group>
          </Form>
        </div>
      ) : (
        <div className="success-message">
          <Image centered size="small" src={image?.comprobado} />
          <h2 className="success-message__header">
            Su documento ha sido presentado
          </h2>
          <h3 className="success-message__nombre">
            {values.nombre
              ? values.nombre + " " + values.apellido
              : "Heriberto Gonzalez"}
          </h3>
          <p className="success-message__parrafo">
            Revisaremos su información y pronto nos pondremos en contacto.
          </p>
          <Button onClick={() => handleFinish()} primary>
            Seguir navegando
          </Button>
        </div>
      )}
    </div>
  );
}
