import React, { useState } from "react";
import { Image, Button, Icon, Confirm, Popup } from "semantic-ui-react";
import { BasicModal } from "../../../Shared";
import { image } from "../../../../assets";
import { ENV } from "../../../../utils";
import { Formulario } from "../../../../api";
import { useAuth, useFormatoParrafo } from "../../../../hooks";
import { ComentarioForm } from "../ComentarioForm";

import { AccordionGestionHumana } from "../AcorGestionHumana";

const formularioController = new Formulario();

export function GestionHumanaItem(props) {
  const { formulario, onReload } = props;
  const [showModal, setShowModal] = useState(false);
  const [showNotaModal, setShowNotaModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");

  const { accessToken } = useAuth();

  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [isAccion, setIsAccion] = useState(false);

  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onOpenCloseNotaModal = () =>
    setShowNotaModal((prevState) => !prevState);
  const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);

  const openResumen = () => {
    onOpenCloseModal();
  };

  const openAddNotas = () => {
    setTitleModal(`Crear nota a ${formulario.nombre} ${formulario.apellido}`);
    onOpenCloseNotaModal();
  };

  const openSelecionadoDescartadoConfirm = () => {
    setIsAccion(false);
    setConfirmMessage(
      formulario.accion === "nuevo" ||
        formulario.accion === "descartado" ||
        formulario.accion === "contratado"
        ? `Selecionar prospecto ${formulario.nombre} ${formulario.apellido}`
        : `Descartar el prospecto ${formulario.nombre} ${formulario.apellido}`
    );
    onOpenCloseConfirm();
  };

  // Logica para Selecionar y Descartar un prospecto.
  const onSelecionadoDescartado = async () => {
    try {
      if (
        formulario.accion === "nuevo" ||
        formulario.accion === "descartado" ||
        formulario.accion === "contratado"
      ) {
        await formularioController.updateFormularioAccion(
          accessToken,
          formulario._id,
          { accion: "seleccionado" }
        );
      } else {
        await formularioController.updateFormularioAccion(
          accessToken,
          formulario._id,
          { accion: "descartado" }
        );
      }
      onReload();
      onOpenCloseConfirm();
    } catch (error) {
      console.log(error);
    }
  };

  // Logica Confirmar para Contractar prospecto
  const openContractarConfirm = () => {
    setIsAccion(true);
    setConfirmMessage(
      `¿Esta contractando a ${formulario.nombre} ${formulario.apellido}?`
    );
    onOpenCloseConfirm();
  };

  // Logica para Contractar
  const onContracto = async () => {
    try {
      await formularioController.updateFormularioAccion(
        accessToken,
        formulario._id,
        { accion: "contratado" }
      );
      onReload();
      onOpenCloseConfirm();
    } catch (error) {}
  };

  return (
    <>
      <div className="flex justify-between items-center border-b-[1px] border-blue-400 mb-2 pb-2">
        <div className="flex items-center">
          <Image
            className="h-[20px] mr-1"
            size="mini"
            rounded
            src={
              formulario.avatar
                ? `${ENV.BASE_PATH}/${formulario.avatar}`
                : image.noAvatar
            }
          />
          <div>
            <p className="font-semibold text-sm md:text-lg md:mr-2">
              {useFormatoParrafo(formulario.nombre)}{" "}
              {useFormatoParrafo(formulario.apellido)}
            </p>
          </div>
        </div>

        <div className="ml-0">
          <Popup
            content="Resumen"
            trigger={
              <Button size="tiny" icon color="facebook" onClick={openResumen}>
                <Icon name="eye" />
              </Button>
            }
          />
          <Popup
            content="Documento"
            trigger={
              <Button
                size="tiny"
                icon
                as="a"
                href={
                  formulario.document
                    ? `${ENV.BASE_PATH}/${formulario.document}`
                    : null
                }
                target="_blank"
              >
                {formulario.document ? (
                  <Icon className="text-yellow-800" name="file word" />
                ) : (
                  <Icon className="text-yellow-800" name="ban" />
                )}
              </Button>
            }
          />
          {(formulario.accion === "nuevo") |
          ((formulario.accion === "descartado") |
            (formulario.accion === "contratado")) ? (
            <Popup
              content="Seleccionar"
              trigger={
                <Button
                  size="tiny"
                  icon
                  color={"green"}
                  onClick={openSelecionadoDescartadoConfirm}
                >
                  <Icon className="text-white" name={"checkmark"} />
                </Button>
              }
            />
          ) : (
            <Popup
              content="Descartar"
              trigger={
                <Button
                  size="tiny"
                  icon
                  color={"red"}
                  onClick={openSelecionadoDescartadoConfirm}
                >
                  <Icon className="text-white" name={"dont"} />
                </Button>
              }
            />
          )}
          {(formulario.accion === "descartado") |
          (formulario.accion === "contratado") ? null : (
            <Popup
              content="Contratar"
              trigger={
                <Button
                  size="tiny"
                  icon
                  color="teal"
                  onClick={openContractarConfirm}
                >
                  <Icon className="text-white" name="building" />
                </Button>
              }
            />
          )}
          {formulario.accion === "seleccionado" ? (
            <Popup
              content="Agregar Notas"
              trigger={
                <Button size="tiny" icon color="yellow" onClick={openAddNotas}>
                  <Icon className="text-white" name="sticky note" />
                </Button>
              }
            />
          ) : null}
        </div>
      </div>

      <BasicModal
        show={showModal}
        close={onOpenCloseModal}
        title={titleModal}
        size="large"
      >
        <AccordionGestionHumana
          onClose={onOpenCloseModal}
          onReload={onReload}
          formulario={formulario}
        />
      </BasicModal>

      {/* Agregar Notas */}
      <BasicModal
        show={showNotaModal}
        close={onOpenCloseNotaModal}
        title={titleModal}
        size="tiny"
      >
        <ComentarioForm
          close={onOpenCloseNotaModal}
          onReload={onReload}
          formulario={formulario}
        />
      </BasicModal>

      <Confirm
        open={showConfirm}
        onCancel={onOpenCloseConfirm}
        onConfirm={isAccion ? onContracto : onSelecionadoDescartado}
        content={confirmMessage}
        size="mini"
      />
    </>
  );
}
