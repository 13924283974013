import React, { useState, useEffect } from "react";
import {
  Accordion,
  Icon,
  Tab,
  Table,
  Grid,
  Image,
  Button,
  Loader,
} from "semantic-ui-react";
import { size, map } from "lodash";
import { image } from "../../../../assets";
import { Formulario } from "../../../../api";
import { ENV } from "../../../../utils";
import ReactWhatsapp from "../../../../utils/whatsapp/lib";
import { useAuth } from "../../../../hooks";
const notasController = new Formulario();

export function AccordionGestionHumana(props) {
  const { formulario } = props;
  const [notas, setNotas] = useState(null);

  const { accessToken } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        setNotas(null);
        const response = await notasController.getNotas(
          accessToken,
          formulario._id
        );

        setNotas(response);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [formulario]);

  const panes = [
    {
      menuItem: {
        key: "1",
        content: "Datos Personales",
      },
      render: () => (
        <Tab.Pane>
          <Informacion formulario={formulario} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "2",
        content: "Experiencia Laboral",
      },
      render: () => (
        <Tab.Pane>
          <Laboral formulario={formulario} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "3",
        content: "Experiencia Acamedica",
      },
      render: () => (
        <Tab.Pane>
          <Acamedica formulario={formulario} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "4",
        content: "Familias",
      },
      render: () => (
        <Tab.Pane>
          <Familia formulario={formulario} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "5",
        icon: "sticky note",
        content: "Notas",
      },
      render: () => (
        <Tab.Pane>
          <Notas notas={notas} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <div className="mb-4 flex items-center">
        <Image
          size="tiny"
          rounded
          src={
            formulario.avatar
              ? `${ENV.BASE_PATH}/${formulario.avatar}`
              : image.noAvatar
          }
        />
        <div className="text-[18px] ml-2 md:text-3xl">
          <h2>
            {formulario.nombre} {formulario.apellido}
          </h2>
        </div>
      </div>
      <div>
        <Tab
          panes={panes}
          menu={{
            className: `flex flex-row flex-wrap items-center justify-left`,
          }}
          className="accord-form__content"
        />
      </div>
    </>
  );
}

function Laboral({ formulario }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    // const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);

    // this.setState({ activeIndex: newIndex });
  };

  return (
    <>
      {formulario?.experiencia.map((item, index) => (
        <Accordion key={index}>
          <Accordion.Title
            active={activeIndex === index}
            index={index}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            {item.empresa}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === index}>
            <Table singleLine celled selectable color="orange">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Informacion</Table.HeaderCell>
                  <Table.HeaderCell width={16}>Descripcion</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Cargo</Table.Cell>
                  <Table.Cell>{item.cargo}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Departamento</Table.Cell>
                  <Table.Cell>{item.departamento}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Salario</Table.Cell>
                  <Table.Cell>{item.salario}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Personal a Cargo</Table.Cell>
                  <Table.Cell>{item.personal_cargo}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Ciudad</Table.Cell>
                  <Table.Cell>{item.ciudad}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Año</Table.Cell>
                  <Table.Cell>{item.anno}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>¿Cargo actual?</Table.Cell>
                  <Table.Cell>{item.cargo_actual}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Accordion.Content>
        </Accordion>
      ))}
    </>
  );
}
function Acamedica({ formulario }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    // const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);

    // this.setState({ activeIndex: newIndex });
  };

  return (
    <>
      {formulario?.academica.map((item, index) => (
        <Accordion key={index}>
          <Accordion.Title
            active={activeIndex === index}
            index={index}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            {item.titulo}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === index}>
            <Table singleLine celled selectable color="orange">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Datos</Table.HeaderCell>
                  <Table.HeaderCell width={16}>Descripcion</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell>Centro</Table.Cell>
                  <Table.Cell>{item.centro}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Año</Table.Cell>
                  <Table.Cell>{item.anno}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Accordion.Content>
        </Accordion>
      ))}
    </>
  );
}
function Familia({ formulario }) {
  return (
    <>
      {formulario?.familia.map((item, index) => (
        <Table key={index} singleLine celled selectable color="orange">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Datos</Table.HeaderCell>
              <Table.HeaderCell width={16}>Descripcion</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>Conyugue</Table.Cell>
              <Table.Cell>{item.nombre_conyugue}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Fecha Nacimiento</Table.Cell>
              <Table.Cell>{item.fecha_nacimiento}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Telefono</Table.Cell>
              <Table.Cell>{item.telefono}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Cantidad de Hijos</Table.Cell>
              <Table.Cell>{item.hijos}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Nombre de la Madre</Table.Cell>
              <Table.Cell>{item.madre}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Nombre del Padre</Table.Cell>
              <Table.Cell>{item.padre}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      ))}
    </>
  );
}
function Informacion({ formulario }) {
  return (
    <>
      <Grid className="items-center">
        <Grid.Column width={16}>
          <Table
            className="text-2xl"
            singleLine
            celled
            selectable
            color="orange"
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Informacion</Table.HeaderCell>
                <Table.HeaderCell width={16}>Descripcion</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>Nombre</Table.Cell>
                <Table.Cell>{formulario.nombre}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Apellido</Table.Cell>
                <Table.Cell>{formulario.apellido}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Documento Identidad</Table.Cell>
                <Table.Cell>{formulario.documento_identidad}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Fecha Nacimiento</Table.Cell>
                <Table.Cell>{formulario.fecha_nacimiento}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Direccion</Table.Cell>
                <Table.Cell>{formulario.direccion}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Sector o Barrio</Table.Cell>
                <Table.Cell>{formulario.sector_barrio}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Ciudad</Table.Cell>
                <Table.Cell>{formulario.ciudad}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Telefono</Table.Cell>
                <Table.Cell>
                  {formulario.telefono1}{" "}
                  <ReactWhatsapp
                    className="mx-4 text-2xl text-white font-bold rounded-md bg-green-400 p-1"
                    number={formulario.telefono1}
                    message={`Hola ${formulario.nombre}, le hablados de *Inversiones Catelli*, Hemos recibido su Hoja de Vida, nos gustaria contactarnos con usted`}
                  >
                    <Icon name="whatsapp" />
                  </ReactWhatsapp>
                  {/* </Button> */}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Correo Electronico</Table.Cell>
                <Table.Cell>
                  {formulario.correo_electronico}{" "}
                  <Button icon color="blue">
                    <Icon
                      name="send"
                      onClick={() =>
                        (window.location = `mailto:${formulario.correo_electronico}`)
                      }
                    />
                  </Button>{" "}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Movil</Table.Cell>
                <Table.Cell>
                  {formulario.telefono2}
                  <ReactWhatsapp
                    className="mx-4 text-2xl text-white font-bold rounded-md bg-green-400 p-1"
                    number={formulario.telefono2}
                    message={`Hola ${formulario.nombre}, le hablados de *Inversiones Catelli*, Hemos recibido su Hoja de Vida, nos gustaria contactarnos con usted`}
                  >
                    <Icon name="whatsapp" />
                  </ReactWhatsapp>
                  {/* </Button> */}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>¿Tiene Licencia de Conducir?</Table.Cell>
                <Table.Cell>{formulario.licencia_tiene}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Categoria de Licencia</Table.Cell>
                <Table.Cell>{formulario.categoria_licencia}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>¿Vehiculo Propio?</Table.Cell>
                <Table.Cell>{formulario.vehiculo_propio}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    </>
  );
}
function Notas({ notas }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;

    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  if (!notas) return <Loader active inline="centered" />;
  if (size(notas) === 0) return "No hay notas";

  return (
    <>
      {map(notas, (nota, index) => (
        <Accordion key={index} fluid styled>
          <Accordion.Title
            active={activeIndex === index}
            index={index}
            onClick={handleClick}
          >
            <div className="text-lg text-blue-800">
              <Icon name="dropdown" /> {nota.asunto}
            </div>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === index}>
            <p className="text-[18px] mx-8">{nota.notas}</p>
          </Accordion.Content>
        </Accordion>
      ))}
    </>
  );
}
