import * as Yup from "yup";

export function initialValues(value) {
  const experiencia = Array.isArray(value)
    ? value.map((experienciaItem) => ({
        empresa: experienciaItem.empresa || "",
        cargo: experienciaItem.cargo || "",
        ciudad: experienciaItem.ciudad || "",
        salario: experienciaItem.salario || "0",
        personal_cargo: experienciaItem.personal_cargo || "0",
        departamento: experienciaItem.departamento || "",
        anno: experienciaItem.anno || "",
        cargo_actual: experienciaItem.cargo_actual || "no",
      }))
    : [{}];

  return { experiencia };
}

export function validationSchema() {
  return Yup.object({
    experiencia: Yup.array().of(
      Yup.object({
        empresa: Yup.string().required("Empresa es Requerido."),
        cargo: Yup.string().required("Cargo, Es requerido"),
        ciudad: Yup.string().required("Ciudad es requerida"),
        salario: Yup.string().required("Salario o monto, es requerido"),
        personal_cargo: Yup.number().required(false),
        departamento: Yup.string().required(false),
        anno: Yup.string().required("Año, es requerido"),
        cargo_actual: Yup.string().required(false),
      })
    ),
  });
}
