import React from "react";
import { Routes, Route } from "react-router-dom";
import { ClientLayout } from "../layouts";
import {
  Home,
  Formulario,
  Contact,
  Nosotros,
  GestionPages,
  PagesSociosComerciales,
  PagesProductos,
} from "../pages/web";

export function WebRouter() {
  const loadLayout = (Layout, Page) => {
    return (
      <Layout>
        <Page />
      </Layout>
    );
  };

  return (
    <Routes>
      <Route path="/" element={loadLayout(ClientLayout, Home)} />
      {/* <Route path="/cursos" element={loadLayout(ClientLayout, Courses)} />
      <Route path="/blog" element={loadLayout(ClientLayout, Blog)} />
      <Route path="/blog/:path" element={loadLayout(ClientLayout, Post)} /> */}
      <Route
        path="/recursohumano"
        element={loadLayout(ClientLayout, GestionPages)}
      />
      <Route
        path="/recursohumano/formulario"
        element={loadLayout(ClientLayout, Formulario)}
      />
      <Route path="/contacto" element={loadLayout(ClientLayout, Contact)} />
      <Route path="/nosotros" element={loadLayout(ClientLayout, Nosotros)} />
      <Route
        path="/socios-comerciales"
        element={loadLayout(ClientLayout, PagesSociosComerciales)}
      />
      <Route
        path="/productos"
        element={loadLayout(ClientLayout, PagesProductos)}
      />
    </Routes>
  );
}
