import * as Yup from "yup";

export function initialValues(value) {
  return {
    nombre: value.nombre || "",
    apellido: value.apellido || "",
    estado_civil: value.estado_civil || "soltero",
    documento_identidad: value.documento_identidad || "",
    fecha_nacimiento: value.fecha_nacimiento || "",
    genero: value.genero || "Masculino",
    correo_electronico: value.correo_electronico || "",
    telefono1: value.telefono1 || "0",
    telefono2: value.telefono2 || "0",
    direccion: value.direccion || "",
    sector_barrio: value.sector_barrio || "",
    ciudad: value.ciudad || "",
    licencia_tiene: value.licencia_tiene || "no",
    categoria_licencia: value.categoria_licencia || "no",
    vehiculo_propio: value.vehiculo_propio || "no",
  };
}

export function validationSchema() {
  return Yup.object().shape({
    nombre: Yup.string().required(true),
    apellido: Yup.string().required(true),
    estado_civil: Yup.string(true).required(true),
    documento_identidad: Yup.string().required(true),
    fecha_nacimiento: Yup.string().required(true),
    genero: Yup.string().required(true),
    correo_electronico: Yup.string().required(true),
    telefono1: Yup.string()
      // .min(11, "Debe tener como minimo 11 Caracteres - Formato 180985544444")
      .max(13, "Debe tener como máximo 13 caracteres - Formato 18098554444")
      .required(true),
    telefono2: Yup.string()
      // .min(11, "Debe tener como minimo 11 Caracteres  - Formato 18098554444")
      .max(13, "Debe tener como máximo 13 caracteres  - Formato 18098554444")
      .required(true),
    direccion: Yup.string().required(true),
    sector_barrio: Yup.string().required(true),
    ciudad: Yup.string().required(true),
    licencia_tiene: Yup.string().required(true),
    categoria_licencia: Yup.string().required(true),
    vehiculo_propio: Yup.string().required(true),
  });
}
