import React from "react";
import { Helmet } from "react-helmet";
import { Hero } from "../../../components/Web";

export function Nosotros(props) {
  return (
    <>
      <Helmet>
        <title>Inversiones Catelli | Sobre Nosotros</title>
        <meta
          name="description"
          content="Sobre nosotros | Somos Catelli "
          data-react-helmet="true"
        />
      </Helmet>
      <section className="hero__secciones">
        {/* <HeroSecciones seccion={"nosotros"} /> */}
        <Hero seccion={"sobrenosotros"} />
      </section>
      {/* <section className="contenedor">
        <h2>slide de productos</h2>
      </section> */}
      <section
        className="container mx-auto mt-10 h-[100vh]"
        // data-aos="fade-down"
        // data-aos-delay="300"
      >
        <div className="about__container">
          <h2 className="text-4xl font-semibold mb-2 ">Nuestra historia</h2>
          <p className="text-2xl font-normal text-justify ">
            <strong>Inversiones Catelli</strong>, fue fundada el 13 de febrero
            del 2006, nace de la visión de 3 empresarios, los señores:
            <strong> Segundo Polanco, Andrés León y Leonardo Morel</strong>, con
            más de 30 años de experiencia en distribución de productos de
            consumo masivo en el Cibao, en las pronvicias de Puerto Plata,
            Santiago y Valverde. Por el buen desempeño y sostenibilidad en su
            repectivos mercados Colgate Palmolive les ofrece la oportunidad de
            distribuir y comercializar sus productos en las zonas del{" "}
            <strong>
              Distrito Nacional, Santo Domingo Norte y Monte plata
            </strong>
            . En el año 2016 Inversiones Catelli pasa a ser distribuidora
            autorizada de la zona de Santo Domingo Oriental.
            <br /> <br />
            Tenemos más de <strong>15 años</strong> de experiencia realizando
            una gestión de venta excepcional, generando un crecimiento continuo
            de ventas y cartera de clientes. Contamos con equipos altamente
            capacitados y comprometidos en lograr los objetivos, asegurando la
            satisfacción y la confianza de nuestro suplidores y clientes.
          </p>
        </div>
      </section>
    </>
  );
}
